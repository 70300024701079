
import  { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import UserRoleService from "../../jsx/api/UserRoleService";
import { ShowToast,getUserDetail } from '../../jsx/Common/Commonentry';
import  secureLocalStorage  from  "react-secure-storage";
export const useToken = () => {
  const getToken = () => {
    
    const tokenString = secureLocalStorage.getItem('token');
    return tokenString ? JSON.parse(tokenString) : null;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    secureLocalStorage.setItem('token', JSON.stringify(userToken));
    setToken(userToken);
  };

  return [token, saveToken];

}

/**
 * @returns user's priveleges
 */
export const usePrivileges = () => {
  
  const getPrivileges = () => {
    
    const priv = secureLocalStorage.getItem('privileges');
    return JSON.parse(priv);
  };


  const [privileges, setPrivileges] = useState(getPrivileges);

  const savePrivileges = newPrivileges => {
    secureLocalStorage.setItem('privileges', JSON.stringify(newPrivileges));
    setPrivileges(newPrivileges);
  };
  
  if (!privileges) { 
    UserRoleService.PermissionGet(0)//1)
    .then((res) => { 
      if (res.data!==null) {
        console.warn('call....global');
          savePrivileges(res.data);
      } 
    })
    .catch((err) => {
      ShowToast(err.message);
    });
   

  }


  return [privileges, savePrivileges];
};

// import MainPagetitle from '../../layouts/MainPagetitle';
import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CMS_EventService from "../api/CMS_EventService";
import ComboSerive from "../api/ComboSerive";

const EventMasterSearch = ({ isRefresh }) => {
    const [searchData, setsearchData] = useState([]);
    const [searchFilter, setsearchFilter] = useState({
        EventName: ''
        , Type: '-1'
    });


    //#region Search
    useEffect(() => {
        CMS_EventService.CMS_EventSearch(searchFilter)
            .then((response) => {
                setsearchData(response.data);

            })
            .catch((error) => {
                console.error("Error fetching Event Search:", error);
            });
    }, [isRefresh]);
    const EventSearch = (e) => {
        e.stopPropagation();
        e.preventDefault();

        CMS_EventService.CMS_EventSearch(searchFilter)
            .then((response) => {
                setsearchData(response.data);

            })
            .catch((error) => {
                console.error("Error fetching Event Search:", error);
            });

    };
    //#endregion
    function handleChange(evt) {
        setsearchFilter({
            ...searchFilter,
            [evt.target.name]: evt.target.value,
        });
    }

    return (
        <>
            <div className="card">
                <div className='col-lg-12 p-3 row'>

                    <div className="form-group col-lg-3">
                        <label > Event Name<span className="text-danger">*</span>
                        </label>
                        <input
                            type="text"
                            id="EventName"
                            name="EventName"
                            className="form-control"
                            placeholder="Event Name"
                            value={searchFilter.EventName}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group col-lg-3">
                        <label >Type<span className="text-danger">*</span>
                        </label>
                        <select className="border default-select dropdown-item p-2"
                            id="Type"
                            name="Type"
                            value={searchFilter.Type}
                            onChange={handleChange}
                        >
                            <option value="-1">Select All</option>
                            <option value="Banner">Banner</option>
                            <option value="Event">Event</option>
                            <option value="Video">Video</option>
                        </select>
                    </div>

                    <div className="form-group col-lg-1">
                        <label></label>
                        <button type="submit" className="btn btn-primary mb-3" onClick={EventSearch}>Search</button>
                    </div>
                </div>

                <div className="card-body p-0">
                    <div
                        className="table-active table-responsive"
                        style={{ overflowX: "auto", maxHeight: "400px" }}
                    >
                        <div className="table-responsive active-projects manage-client">

                            <div id="finance-tblwrapper" className="dataTables_wrapper" >
                                <table id="empoloyees-tbl1" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                    <thead>
                                        <tr>
                                            <th>Event Name</th>
                                            <th>Type</th>
                                            <th>From Date</th>
                                            <th>To Date</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {searchData.map((item, index) => (
                                            <tr key={item.ID}>
                                                <td>
                                                    <Link to={"/EventMaster/" + item.ID} className="text-primary">{item.EventName}</Link>
                                                </td>
                                                <td>
                                                    <span>{item.Type}</span>
                                                </td>

                                                <td>
                                                    <span>{item.FromDateName}</span>
                                                </td>
                                                <td>
                                                    <span>{item.ToDateName}</span>
                                                </td>
                                                <td>
                                                    <span>{item.Status == 1 ? 'Active' : 'InActive'}</span>
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EventMasterSearch;
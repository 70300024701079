import secureLocalStorage  from  "react-secure-storage";
import http from "./Endpoint";
import { GetUserDetail } from '../Common/Commonentry';
// import { getProfile } from '../../services/AuthService';
const LOCAL_STORAGE="privileges";
const userDetail=GetUserDetail();
class UserRoleService{
  
  
  isAllowToShow(allowedRoles) {
    
    const privileges = JSON.parse(secureLocalStorage.getItem(LOCAL_STORAGE));
    return (
       userDetail.UserRoleFkey === -100 ||
        (privileges &&
            Array.isArray(privileges) &&
            (privileges.find(x => x.MenuId === allowedRoles && x.RoleId ===  userDetail.UserRoleFkey && x.View) ||
                allowedRoles === "*"))
    );
}
      // PermissionUpdate(formData) {
      //   return http().post("/App/UserPermissionUpdate", {
      //     XML: formData.XML,
      //     ModuleID: formData.ModuleID,
      //     RoleId: formData.RoleId, 
      //     UserID:formData.UserID,
      //   }, {
      //     headers: {
      //       'Content-Type': 'application/json',
      //     }
      //   })
      // }
      PermissionUpdate(data) {
        return http().post("/App/UserPermissionUpdate", data);
      }
      PermissionGet(RoleId) {
        return http().get(`/App/UserPermissionGet?RoleId=${RoleId}`);
      }
    
      PermissionSearch() {
        return http().get(`/App/UserPermissionSearch`);
      }
}
export default new UserRoleService();
// import MainPagetitle from '../../layouts/MainPagetitle';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CMS_SubCategoryService from '../api/CMS_SubCategoryService';
import ComboSerive from "../api/ComboSerive";


const SubCategorySearch = ({ isRefresh }) => {
    const [searchData, setsearchData] = useState([]);
    const [category, setcategory] = useState();
    //#region combo/auto suggest
    useEffect(() => {
        ComboSerive.FillOtherCombo("CategoryName", "")
            .then((response) => {
                setcategory(response.data);
            })
            .catch((error) => {
                console.error("Error fetching combo api:", error);
            });
    }, []);
    //#endregion

    //#region Search
    const [searchFilter, setsearchFilter] = useState({
        CategoryKey: '-1'
        , SubCategoryName: ''
    });

    useEffect(() => {
        CMS_SubCategoryService.CMS_SubCategorySearch(searchFilter)
            .then((response) => {

                setsearchData(response.data);

            })
            .catch((error) => {
                console.error("Error fetching sub category search:", error);
            });


    }, [isRefresh]);
    const SubCategorySearch = (e) => {
        e.stopPropagation();
        e.preventDefault();

        CMS_SubCategoryService.CMS_SubCategorySearch(searchFilter)
            .then((response) => {
                // console.success("category search successfully", success);
                setsearchData(response.data);

            })
            .catch((error) => {
                console.error("Error fetching sub category search:", error);
            });

    };
    //#endregion
    function handleChange(evt) {
        setsearchFilter({
            ...searchFilter,
            [evt.target.name]: evt.target.value,
        });
    }
    return (
        <>
            <div className="card">
                <div className='col-lg-12 p-3 row'>
                    <div className="form-group col-lg-3">
                        <label > Category<span className="text-danger">*</span>
                        </label>
                        <select className="border default-select dropdown-item p-2"
                            id="CategoryKey"
                            name="CategoryKey"
                            value={searchFilter.CategoryKey}
                            onChange={handleChange}
                        >
                            <option value="-1">Select All</option>
                            {Array.isArray(category) && category.map((item) => (
                                <option key={item.ComboID} value={item.ComboID}>{item.ComboValue}</option>
                            ))}

                        </select>
                    </div>
                    <div className="form-group col-lg-3">
                        <label >Sub Category<span className="text-danger">*</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="SubCategoryName"
                            name="SubCategoryName"
                            value={searchFilter.SubCategoryName}
                            onChange={handleChange}
                            placeholder="Sub Category" />
                    </div>
                    <div className="form-group col-lg-1">
                        <label></label>
                        <button type="submit" className="btn btn-primary mb-3" onClick={SubCategorySearch}>Search</button>
                    </div>
                </div>

                <div className="card-body p-0">
                    <div
                        className="table-active table-responsive"
                        style={{ overflowX: "auto", maxHeight: "400px" }}
                    >
                        <div className="table-responsive active-projects manage-client">

                            <div id="finance-tblwrapper" className="dataTables_wrapper no-footer">
                                <table id="empoloyees-tbl1" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                    <thead>
                                        <tr>
                                            <th>Sub Category</th>
                                            <th>Category</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {searchData.map((item, index) => (
                                            <tr key={item.CategoryKey}>
                                                <td>
                                                    <Link to={"/SubCategoryEntry/" + item.SubCategoryKey} className="text-primary">{item.SubCategoryName}</Link>
                                                </td>
                                                <td>
                                                    <span>{item.CategoryName}</span>
                                                </td>
                                                <td>
                                                    <span>{item.Status == 1 ? "Active" : "Inctive"}</span>
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SubCategorySearch;
import http from "./Endpoint";

class CMS_Category2ImageService {
  

  CMS_Category2ImageSave(formData) {
    debugger
    return http().post("/CMS/CMS_Category2ImageSave", {
      ID: formData.ID,
      ImageData: formData.ImageData,
      ImageName: formData.FileName,
      CategoryKey: formData.CategoryKey,
      Action: formData.Action,

      
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
  }
  CMS_Category2ImageSearch(data) {
    return http().get(`/CMS/CMS_Category2ImageSearch?CategoryKey=${data.CategoryKey}`);
 
  }

  CMS_Category2ImageGET(ID) {
  
    return http().get(`/CMS/CMS_Category2ImageGET?ID=${ID}`);
 
  }

}
export default new CMS_Category2ImageService();
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { usePrivileges } from "../constant/globalState";
import UserRoleService from '../api/UserRoleService'
import { GetUserDetail } from '../Common/Commonentry';
const userDetail=GetUserDetail();
const RequireAuth = ({ allowedRoles }) => {
    const [privileges] = usePrivileges();
    const location = useLocation();


    const user = {
        UserRoleName: userDetail && userDetail.RoleName,
    };

    const checkAdminRole = (objUser) => {
        return objUser.UserRoleName.toUpperCase().includes("ADMIN");
    };

    const isAdmin = checkAdminRole(user);
    
    return (
        //    privileges &&
        //           Array.isArray( privileges) &&
        //            (privileges.find(((name) => name === allowedRoles) ) ||  allowedRoles==="*")
        <>
            {isAdmin ? (
                <Outlet />
            ) : (
                UserRoleService.isAllowToShow(allowedRoles)
                    ? <Outlet />
                    : privileges?.user
                        // ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                        // : <Navigate to="/login" state={{ from: location }} replace />
            )}
        </>

    );
}

export default RequireAuth;
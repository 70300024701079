import React, { useState, Fragment,useEffect } from "react";
import UserRoleService from '../api/UserRoleService';
import ComboSerive from "../api/ComboSerive";
import DataTable from 'react-data-table-component';
import { useNavigate } from "react-router-dom";
import { Button, Row, Form } from "react-bootstrap";

const UserPermissionSearch = () => {
    const navigate = useNavigate();
    const [searchData, setsearchData] = useState([]);
    //#region Search

    useEffect(() => {
        UserRoleService.PermissionSearch()
            .then((response) => {
                setsearchData(response.data);
            })
            .catch((error) => {
                console.error("Error fetching Permission search:", error);
            });


    }, []);
   
    //#endregion
   
    //#region GRID
  
    const columns = [
      {
        button: true,
        width: "10rem", // Set the width to 150 pixels
        //cell:(row)=><button onClick={clickHandler} id={row.ID}>Action</button>
        cell: (row) => (
          <div className="openbtn">
            <button
              type="button"
              className="btn btn-info btn-sm"
         
              onClick={() => open(row.CategoryKey)}
            >
              select
            </button>
  
          </div>
        ),
  
      },
      // {
      //   name: "No.",
      //   selector: (row) => row.id,
      //   sortable: true,
      //   width: "7rem", // Set the width to 150 pixels
      // },
      {
        name: "Role Name",
        selector: (row) => row.CategoryName,
        sortable: true,
        width: "55rem", // Set the width to 150 pixels
      },
      
     
      {
        name: "Status",
        selector: (row) => row.CategoryStatus == 1 ?"Active":"Inctive",
        sortable: true,
        width: "15rem", // Set the width to 150 pixels
      },
     
  
    ];
  
    //#endregion
    const open = (key) => {
     navigate('/UserPermission/' + key)
    }
    
    return (
      <Fragment>
        {/* <MainPagetitle mainTitle="Finance" pageTitle={'Finance'}  parentTitle={'Home'} /> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12 ">
              <div className="card h-auto">
                <div className="card-header">
                  <h4 className="heading mb-0">User Permission </h4>
                </div>
                <div className="card-body">
                  <div className="col-xl-12 col-xxl-12">
                    <div className="card">
                     
                      <div className="table-responsive col-lg-12" style={{ overflowX: 'auto', maxHeight: '400px' }}>
                        <DataTable
                          title=""
                          columns={columns}
                          data={searchData}
                          defaultSortFieldID={1}
                          pagination
  
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
          </div>
        </div>
      </Fragment>
    );
  }

export default UserPermissionSearch;
import http from "./Endpoint";

class CMS_PDFDownloadService {
  
  CMS_PDFDownloadSave(formData) {
    
    return http().post("/CMS/CMS_PDFDownloadSave", {
      ID: formData.ID,
      PDFData: formData.PDFData,
      FileName: formData.FileName,
      Action: formData.Action,
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
  }

  CMS_PDFDownloadGET(ID) {
    return http().get(`/CMS/CMS_PDFDownloadGET?ID=${ID}`);
  }

  CMS_PDFDownloadSearch() {
    return http().get(`/CMS/CMS_PDFDownloadSearch`);
  }

}
export default new CMS_PDFDownloadService();
import http from "./Endpoint";

class CMS_SubCategoryService {
  
  CMS_SubCategorySave(data) {
    return http().post("/CMS/CMS_SubCategorySave", data);
  }
  CMS_SubCategoryGet(SubCategoryKey) {
    return http().get(`/CMS/CMS_SubCategoryGet?SubCategoryKey=${SubCategoryKey}`);
  }

  CMS_SubCategorySearch(data) {
    return http().get(`/CMS/CMS_SubCategorySearch?CategoryKey=${data.CategoryKey}&SubCategoryName=${data.SubCategoryName}`);
  }

}
export default new CMS_SubCategoryService();
import http from "./Endpoint";

class CMS_InquiryService {
  

  CMS_InquirySave(data) {
    return http().post("/CMS/CMS_InquirySave", data);
  }
  CMS_InquiryGet(ID) {
    return http().get(`/CMS/CMS_InquiryGet?ID=${ID}`);
  }

  CMS_InquirySearch(data) {
    return http().get(`/CMS/CMS_InquirySearch?Status=${data.Status}&ProductKey=${data.ProductKey}&CountryKey=${data.CountryKey}`);
  }

}
export default new CMS_InquiryService();
import http from "./Endpoint";

class CMS_EventService {
  

  CMS_EventSave(formData) {
    
    //return http().post("/CMS/CMS_EventSave", data);

    return http().post("/CMS/CMS_EventSave", {
      ID: formData.ID,
      ImageData: formData.ImageData,
      ImageName: formData.FileName,
      Action: formData.Action,
      EventName: formData.EventName,
      FromDate: formData.FromDate,
      ToDate: formData.ToDate,
      Type: formData.Type,
      Description: formData.Description,
      Status: formData.Status,
      UserID: formData.UserID,
      
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
  }
  CMS_EventGet(ID) {
    return http().get(`/CMS/CMS_EventGet?ID=${ID}`);
  }

  CMS_EventSearch(data) {
    return http().get(`/CMS/CMS_EventSearch?EventName=${data.EventName}&Type=${data.Type}`);
  }

}
export default new CMS_EventService();
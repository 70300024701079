import React, { useState, useEffect, Fragment, useRef, Suspense } from 'react';
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import { Link, useNavigate } from 'react-router-dom';
import CMS_ProductMasterService from "../api/CMS_ProductMasterService";
const Product2Description = ({ ProductKey, Lstdescrp }) => {

  const nav = useNavigate();
  const [searchDescData, setsearchDescData] = useState([]);
  const [detailDescData, setdetailDescData] = useState({
    Index: 0,
    ID: 0,
    Title: '',
    Description: '',
    ProductKey: ProductKey,
    Action: "ADD",
  });
  useEffect(() => {
    if (ProductKey > 0) {
      setsearchDescData(Lstdescrp);
    }
    else {
      handleCancel();
    }
  }, [ProductKey]);


  const handleCancel = () => {

    setdetailDescData({
      Index: 0,
      ID: 0,
      Title: '',
      Description: '',
      ProductKey: ProductKey,
      Action: "ADD",
    });
    nav("/ProductMaster/" + ProductKey);
    if (ProductKey === 0) {
      setsearchDescData([]);
    }
  }
  //#region Save 

  const handleChange = (e) => {
    setdetailDescData({
      ...detailDescData,
      [e.target.name]: e.target.value,
    });
  };


  const SaveDescription = (sAction) => {
    debugger
    let errorMessage = "";
    try {
      if (!detailDescData.Title) {
        errorMessage += "Please enter Title.<br>";
      }
      if (!detailDescData.Description) {
        errorMessage += "Please enter Description.<br>";
      }

      let action;
      if (sAction === 'DELETE') {
        action = 'DELETE';
      } else {

        action = detailDescData.ID > 0 ? 'UPDATE' : 'ADD';
      }
      const updatedData = { ...detailDescData, Action: action };
      if (errorMessage !== "") {

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: errorMessage,
        });
      }
      else {
        CMS_ProductMasterService.CMS_Product2descriptionSave(updatedData)
          .then((response) => {
            setsearchDescData((prevData) => {
              if (action === 'DELETE') {
                return prevData.filter(item => item.ID !== updatedData.ID);
              } else {
                const index = prevData.findIndex(item => item.ID === updatedData.ID);
                if (index > -1) {
                  const newsearchDescData = [...prevData];
                  newsearchDescData[index] = updatedData;
                  return newsearchDescData;
                } else {
                  return [...prevData, updatedData];
                }
              }
            });

            handleCancel();
          })
          .catch((error) => {
            swal("Error occurs while saving Customer Description Save.", "error");
          });
      }
    } catch (error) {
      swal("Error occurs while saving Product Description Save.", error);
    }
  };


  //#endregion

  //#region GET 
  const setDescriptionData = (index) => {

    const updatedData = Lstdescrp.find(item => item.Index === index);
    setdetailDescData(updatedData);
    console.log(detailDescData);
  }
  //#endregion

  return (
    <Fragment>
      {/* <MainPagetitle mainTitle="Finance" pageTitle={'Finance'}  parentTitle={'Home'} /> */}
      <div className="row ">
        <div className="card h-auto">
          <>
            <div className="col-lg-12 p-3 row">
              <div className="col-lg-4">
                <input type="text"
                  className="form-control mb-3"
                  id="Title"
                  name="Title"
                  value={detailDescData.Title}
                  onChange={handleChange}
                  placeholder="Title" />

                <textarea type="text"
                  className="form-control"
                  id="Description"
                  name="Description"
                  value={detailDescData.Description}
                  onChange={handleChange}
                  placeholder="Description" />

                <div className="col-lg-12 p-3 row">
                  <div className="col-lg-3">
                    <input
                      type="button"
                      className="btn btn-outline-primary btn-xs form-upload mx-1"
                      onClick={() => SaveDescription("ADD")}
                      value="Save"
                    />
                  </div>

                  <div className="col-lg-3">
                    <input
                      type="button"
                      className="btn btn-outline-primary btn-xs form-upload  mx-1"
                      onClick={() => handleCancel()}
                      value="Cancel"
                    />
                  </div>
                  {detailDescData && detailDescData.ID > 0 && (
                    <div className="col-lg-3">
                      <button
                        className="btn btn-outline-primary btn-xs form-upload  mx-1"
                        onClick={() => SaveDescription("DELETE")}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div
                className="col-lg-8 table-active table-responsive"
                style={{ overflowX: "auto", maxHeight: "400px" }}
              >
                <div id="finance-tblwrapper" className="dataTables_wrapper">
                  <table
                    id="empoloyees-tbl1"
                    className="table ItemsCheckboxSec dataTable no-footer mb-0"
                  >
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Description</th>

                      </tr>
                    </thead>
                    <tbody>
                      {
                        <>
                          {Array.isArray(searchDescData) &&
                            searchDescData.map((item, index) => (
                              <tr key={item.Index}>
                                <td>
                                  <Link
                                    onClick={() => setDescriptionData(item.Index)}
                                    className="text-primary"
                                  >
                                    {item.Title}
                                  </Link>
                                </td>
                                <td> {item.Description}</td>

                              </tr>
                            ))}
                        </>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </Fragment>
  );
};

export default Product2Description;
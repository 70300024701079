// import MainPagetitle from '../../layouts/MainPagetitle';
import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CMS_EventService from "../api/CMS_EventService";
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import EventMasterSearch from './EventMasterSearch';
import { toast } from "react-toastify";
import { getProfile } from '../../services/AuthService';
import { GETMMDDYYYY } from '../../jsx/Common/Commonentry';
const EventMaster = () => {
    const [refresh, setRefresh] = useState(false);
    let { pagekey } = useParams();
    const nav = useNavigate();
    const [image, setImage] = useState('');
    useEffect(() => {
        document.title = 'Event Master'; // Set your desired page title here
        handleCancel();
        setRefresh(false);
    }, [refresh]);
    const userDetail = JSON.parse(getProfile());
    const [formData, setFormData] = useState({
        ID: 0
        , EventName: ''
        , FromDate: ''
        , ToDate: ''
        , Type: 'Banner'
        , Description: ''
        , Status: false
        , Action: ''
        , UserID: userDetail && userDetail.UserKey
        , FileName: ''
        , ImageData: ''
    });
    const [buttonname, Setbuttonname] = useState('ADD');
    const [showDelButton, setshowDelButton] = useState(false);


    //#region Save Data
    const EventSave = (sAction) => {
        // e.stopPropagation();
        // e.preventDefault();
        let errorMessage = "";
        if (!formData.EventName) {
            errorMessage += "Please enter Event Name.<br>";
        }

        if (formData.FromDate.length < 7) {
            errorMessage += "Please enter From Date.<br>";
        }

        if (formData.ToDate.length < 7) {
            errorMessage += "Please enter To Date.<br>";
        }

        // if (!image) {
        //     errorMessage += "Please select Image for event.<br>";
        // }

        if (sAction === "DELETE") {
            formData.Action = 'DELETE';
        }
        else {
            if (pagekey > 0) {
                formData.Action = 'UPDATE';
            }
            else {
                formData.Action = 'ADD';
            }
        }
        formData.UserID = userDetail && userDetail.UserKey != null ? userDetail.UserKey : 1;
        if (errorMessage !== "") {

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                html: errorMessage,
            });
        }
        else {

            CMS_EventService.CMS_EventSave(formData)
                .then((response) => {

                    swal("Event Master Save Successfully", "", "success");
                    handleCancel();
                    setRefresh(true);
                })
                .catch((error) => {
                    swal("Error occurs while saving Event Master Save.", "error");
                });

        }
    };
    const handleCancel = () => {
        Setbuttonname('ADD');
        setshowDelButton(false);
        setFormData({
            ID: 0
            , EventName: ''
            , FromDate: ''
            , ToDate: ''
            , Type: 'Banner'
            , Description: ''
            , Status: false
            , Action: 'ADD'
            , UserID: userDetail && userDetail.UserKey
            , FileName: ''
            , ImageData: ''
        });
        setImage('');
        nav("/EventMaster");

    }
    function handleChange(evt) {
        const name = evt.target.name;
        let value = "";
        if (name === "Status") {
            value = evt.target.checked;
        }
        else {
            value = evt.target.value;
        }
        setFormData({
            ...formData,
            [evt.target.name]: value,
        });
    }
    const handleImageChange = (e) => {
        const selectedImage = e.target.files[0];
        setImage(selectedImage);

        // Create a FileReader to read the selected image as a Base64 encoded string
        const reader = new FileReader();

        reader.onload = (event) => {
            // Set the Base64 encoded image string in state
            const base64Image = event.target.result;
            const base64Data = base64Image.split(',')[1];
            setImage(base64Data);
            setFormData({
                ...formData,
                FileName: selectedImage.name,
                ImageData: base64Data
            });

        };

        // Read the selected image as a Data URL (Base64 encoded)
        reader.readAsDataURL(selectedImage);
    };

    const handleClearImage = () => {
        setFormData({
            ...formData,
            FileName: '',
            ImageData: ''
        });
        setImage('');
    };



    //#endregion

    //#region Get Data
    useEffect(() => {
    
        if (formData && formData.ImageData) {
          setImage(formData.ImageData);
        } else {
          setImage('');
        }
      }, [formData]);

    useEffect(() => {
        if (pagekey > 0) {
            setEventMaster();
            setFormData({ ...formData, ID: pagekey });
            Setbuttonname('UPDATE');
            setshowDelButton(true);
        }
    }, [pagekey]);


    useEffect(() => {
        if (formData) {
            // Check if FromDate is present and format it
            if (formData.FromDate) {
                const formattedFromDate = GETMMDDYYYY(formData.FromDate);
                setFormData((prevData) => ({
                    ...prevData,
                    FromDate: formattedFromDate,
                }));
            }

            // Check if ToDate is present and format it
            if (formData.ToDate) {
                const formattedToDate = GETMMDDYYYY(formData.ToDate);
                setFormData((prevData) => ({
                    ...prevData,
                    ToDate: formattedToDate,
                }));
            }
        }
    }, [formData.ToDate]);
    function setEventMaster() {
        if (pagekey === undefined) {
            return;
        }
        CMS_EventService.CMS_EventGet(pagekey)
            .then((res) => {

                if (res.data !== null && res.data.ID > 0) {
                    setFormData(res.data);

                }

            })
            .catch((err) => {
                toast.error(err.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    role: "alert",
                });
            });
    }
    //#endregion



    return (
        <Fragment>
            {/* <MainPagetitle mainTitle="Finance" pageTitle={'Finance'}  parentTitle={'Home'} /> */}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-3 col-xxl-4">
                        <div className="card h-auto">
                            <div className="card-header">
                                <h4 className="heading mb-0">Event Master</h4>
                            </div>
                            <div className="card-body">
                                <form className="finance-hr" >
                                    <div className="row">
                                        <div className="form-group col-lg-6 mb-3">
                                            <label className="text-label">Event Name*</label>
                                            <input
                                                type="text"
                                                id="EventName"
                                                name="EventName"
                                                className="form-control"
                                                placeholder="Event Name"
                                                value={formData.EventName}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="form-group col-lg-6 mb-3">
                                            <label className="text-label">From Date*</label>
                                            <input
                                                type="date"
                                                value={formData.FromDate} //"1978-01-04"
                                                className="form-control"
                                                onChange={handleChange}
                                                id="FromDate"
                                                name="FromDate"
                                            />
                                        </div>
                                        <div className="form-group col-lg-6 mb-3">
                                            <label className="text-label">To Date*</label>
                                            <input
                                                type="date"
                                                value={formData.ToDate} //"1978-01-04"
                                                className="form-control"
                                                onChange={handleChange}
                                                id="ToDate"
                                                name="ToDate"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group  col-lg-6 mb-3">
                                            <label>Type<span className="text-danger">*</span>
                                            </label>
                                            <select className="border default-select dropdown-item p-2"
                                                id="Type"
                                                name="Type"
                                                value={formData.Type}
                                                onChange={handleChange}
                                            >
                                                <option value="Banner">Banner</option>
                                                <option value="Event">Event</option>
                                                <option value="Video">Video</option>
                                            </select>
                                        </div>


                                    </div>

                                    <div className="row">
                                        <div className="form-group col-lg-12 mb-3">
                                            <label >Description<span className="text-danger">*</span>
                                            </label>
                                            <textarea type="text"
                                                className="form-control form-txtarea"
                                                rows="2"
                                                id="Description"
                                                name="Description"
                                                value={formData.Description}
                                                onChange={handleChange}
                                                placeholder="Description" />
                                        </div>
                                    </div>

                                    <div className="row">

                                        <div className="form-group col-lg-6 mb-3">
                                            <label >Status<span className="text-danger mx-3"></span>
                                            </label>
                                            <input
                                                type="checkbox"
                                                defaultChecked
                                                className="form-check-input"
                                                id="Status"
                                                name="Status"
                                                value={formData.Status}
                                                checked={formData.Status}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group mb-3">
                                        <input type="file" accept="image/*" onChange={handleImageChange} />
                                        {image &&
                                            <input type='button'
                                                className='btn btn-outline-primary btn-sm form-upload'
                                                onClick={() => handleClearImage('DELETE')}
                                                value="X"
                                            />
                                        }
                                        {image &&
                                            <img src={`data:image/png;base64,${image}`}
                                                alt="Uploaded"
                                                width="100" />}
                                    </div>
                                    <button type="button" className="btn btn-primary mb-3 mx-1" onClick={() => EventSave('ADD')}>{buttonname}</button>
                                    {showDelButton &&
                                        <button type="button" className="btn btn-primary mb-3" onClick={() => EventSave('DELETE')}>Delete</button>}
                                    <button type="button" className="btn btn-primary mb-3 mx-2" onClick={() => handleCancel()}>Cancel</button>

                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-9 col-xxl-8">
                        <EventMasterSearch isRefresh={refresh}></EventMasterSearch>

                    </div>
                </div>
            </div>
        </Fragment >
    );
};


export default EventMaster;
import { SVGICON } from "../../constant/theme";
import UserRoleService from "../../../jsx/api/UserRoleService";
import { USER_ROLES } from "../../../jsx/Common/Enum";

export const MenuList = [
    //Content  UserRoleService.isAllowToShow(USER_ROLES.CategoryEntry) && 
    {
        title: 'Setting',
        classsChange: 'mm-collapse',
        iconStyle: SVGICON.Home,
        content: [

            {

                title: 'Category Master',
                to: '/CategoryEntry',
                role:USER_ROLES.CategoryEntry,
            },
          
            {
                title: 'User Permission',
                to: '/UserPermissionSearch',
                role:USER_ROLES.UserPermission,
            },

        ],
    },
      {
        title: 'CMS',
        classsChange: 'mm-collapse',
        iconStyle: SVGICON.Home,
        content: [
            {
                title: 'Category 2 Image',
                to: '/Category2Image',
                role:USER_ROLES.Category2Image,
            },
            {
                title: 'Sub Category',
                to: '/SubCategoryEntry',
                role:USER_ROLES.SubCategoryEntry,
            },
            {
                title: 'Product Master',
                to: '/ProductMaster',
                role:USER_ROLES.ProductMaster,
            },
            {
                title: 'Product Search',
                to: '/ProductMasterSearch',
                role:USER_ROLES.ProductMaster,
            },
            {
                title: 'PDF Download',
                to: '/PDFDownload',
                role:USER_ROLES.PDFDownload,
            },
            {
                title: 'Event Master',
                to: '/EventMaster',
                role:USER_ROLES.EventMaster,
            },
            // {
            //     title: 'Contact Us',
            //     to: '/ContactUs',
            //     role:USER_ROLES.ContactUs,
            // },
            // {
            //     title: 'Inquiry',
            //     to: '/InquiryEntry',
            //     role:USER_ROLES.InquiryEntry,
            // },
            {
                title: 'Client Master',
                to: '/ClientMaster',
                role:USER_ROLES.ClientMaster,
            },
        ],
    },
   
]
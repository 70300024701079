import swal from 'sweetalert';
import UserService from '../../jsx/api/UserService';
import {
    formatError,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,
    saveProfile
} from '../../services/AuthService';
import  secureLocalStorage  from  "react-secure-storage";

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const NAVTOGGLE = 'NAVTOGGLE';




export function signupAction(email, password, navigate) {

    return (dispatch) => {
        signUp(email, password)
            .then((response) => {
                saveTokenInLocalStorage(response.data);
                runLogoutTimer(
                    dispatch,
                    response.data.expiresIn * 1000,
                    //history,
                );
                dispatch(confirmedSignupAction(response.data));
                navigate('/dashboard');
                //history.push('/dashboard');
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(signupFailedAction(errorMessage));
            });
    };
}

export function Logout(navigate) {
    console.log('before logout');
    console.log(secureLocalStorage.getItem('userDetails')); // Should log null or undefined
    console.log(secureLocalStorage.getItem('privileges')); // Should log null or undefined
    console.log(secureLocalStorage.getItem('User_PROFILE')); // Should log null or undefined
   
    secureLocalStorage.removeItem('userDetails');
    secureLocalStorage.removeItem('privileges');
    secureLocalStorage.removeItem('User_PROFILE');

    console.log('after logout');

    console.log(secureLocalStorage.getItem('userDetails')); // Should log null or undefined
    console.log(secureLocalStorage.getItem('privileges')); // Should log null or undefined
    console.log(secureLocalStorage.getItem('User_PROFILE')); // Should log null or undefined
    navigate('/login');
    return {
        type: LOGOUT_ACTION,
    };
}
// export const Logout = (navigate) => {
    
//     secureLocalStorage.removeItem('userDetails');
//     secureLocalStorage.removeItem('privileges');
//     secureLocalStorage.removeItem('User_PROFILE');
//     navigate('/login');
//     return {
//         type: LOGOUT_ACTION,
//     };
//   };

export function loginAction(email, password, navigate) {
    return (dispatch) => {
        debugger
        UserService.UsermasterAutheicate(email, password)
            .then((response) => {
                
                if (response.data.UserKey > 0) {
                    
                    saveProfile(response.data);
                    var data = {
                          displayName: ""
                        , username: email
                        , expireDate: "Fri Jun 8 2024 21:07:08 GMT+0530"
                        , expiresIn: "996000"
                        , userid: response.data.UserKey
                        , idToken: response.data
                        , kind: "identitytoolkit#VerifyPasswordResponse"
                        , localId: "qmt6dRyipIad8UCc0QpMV2MENSy1"
                        , refreshToken: response.data
                        , registered: true
                    }
                    
                    saveTokenInLocalStorage(data);
                    dispatch(loginConfirmedAction(data));
                    navigate('/dashboard');
                }
                else {
                    swal("Oops", "Invalid username or password", "error");
                }
            })
            .catch((error) => {
                swal("Oops", error.response.data, "error");
            });
    };
}



export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
export const navtoggle = () => {
    return {
        type: 'NAVTOGGLE',
    };
};
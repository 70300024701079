// import MainPagetitle from '../../layouts/MainPagetitle';
import React, { useState, useEffect, Fragment, Suspense, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CMS_ProductMasterService from "../api/CMS_ProductMasterService";
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import ProductImageUpload from './ProductImageUpload';
import Product2Description from './Product2Description';

import { toast } from "react-toastify";
import { getProfile } from '../../services/AuthService';
import ComboSerive from "../api/ComboSerive";
import { Editor } from '@tinymce/tinymce-react';
import { Modal } from 'react-bootstrap'; // Import ProgressBar from react-bootstrap
import EditorModal from './EditorModal';

const ProductMaster = () => {
    let { pagekey } = useParams();
    const [isLoading, setIsLoading] = useState(false); // State to track loading
    const [refresh, setRefresh] = useState(false);
    const userDetail = JSON.parse(getProfile());
    const [buttonname, Setbuttonname] = useState('ADD');
    const [showDelButton, setshowDelButton] = useState(false);
    const [category, setcategory] = useState();
    const [subcategory, setsubcategory] = useState();
    const [detdesc, setdetdesc] = useState([]);
    const [det, setdet] = useState([]);
    const nav = useNavigate();
    const [file, setfile] = useState('');
    const [formData, setFormData] = useState({
        ProductKey: 0
        , ProductCode: ''
        , ProductName: ''
        , ProductCategoryKey: '-1'
        , ProductSubCategoryKey: '-1'
        , ProductIsActive: false
        , ProductDescription: ''
        , ProductSmallDescription: ''
        , ProductSearchKeyWord: ''
        , ProductSmallImage: ''
        , VatPer: 0
        , Action: ''
        , UserID: userDetail && userDetail.UserKey
        , ProImagePath: 'Test'
        , BroucherName: ''
        , BroucherPath : ''
        , BroucherData: ''
    });
    const [txtProDescription, settxtProDescription] = useState(formData.ProductDescription);
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => {
        setShowModal(false);
    };

    const OpenModalDesption = () => {
        setShowModal(true);
        settxtProDescription(formData.ProductDescription);
    };


    useEffect(() => {
        document.title = 'Product Master'; // Set your desired page title here
        if (pagekey === 0) {
            handleCancel();
        }

        setRefresh(false);
    }, [refresh]);
    //#region combo/auto suggest
    useEffect(() => {
        ComboSerive.FillCategory("ItemGroup")
            .then((response) => {
                setcategory(response.data);
            })
            .catch((error) => {
                console.error("Error fetching combo api:", error);
            });
    }, []);

    useEffect(() => {
        ComboSerive.FillOtherCombo("SubCategoryName", "")
            .then((response) => {
                setsubcategory(response.data);
            })
            .catch((error) => {
                console.error("Error fetching combo api:", error);
            });
    }, []);
    //#endregion
    //#region Save Data
    const ProductSave = (sAction) => {

        let errorMessage = "";

        if (!formData.ProductName) {
            errorMessage += "Please enter Product Name.<br>";
        }

        if (formData.ProductCategoryKey === "-1") {
            errorMessage += "Please select Category.<br>";
        }
        if (formData.ProductSubCategoryKey === "-1") {
            errorMessage += "Please select Sub Category.<br>";
        }


        if (sAction === "DELETE") {
            formData.Action = 'DELETE';
        }
        else {
            if (pagekey > 0) {
                formData.Action = 'UPDATE';
            }
            else {
                formData.Action = 'ADD';
            }
        }
        formData.UserID = userDetail && userDetail.UserKey != null ? userDetail.UserKey : 1;

        if (editorRef.current) {
            formData.ProductDescription = editorRef.current.getContent();
        }

        if (errorMessage !== "") {

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                html: errorMessage,
            });
        }
        else {

            CMS_ProductMasterService.CMS_ProductMasterSave(formData)
                .then((response) => {

                    swal("Product Save Successfully", "", "success");
                    handleCancel();
                    setRefresh(true);

                })
                .catch((error) => {
                    swal("Error occurs while saving Product Save.", "error");
                });

        }
    };
    const handleCancel = () => {
        
        Setbuttonname('ADD');
        setshowDelButton(false);
        setFormData({
            ProductKey: 0
            , ProductCode: ''
            , ProductName: ''
            , ProductCategoryKey: '-1'
            , ProductSubCategoryKey: '-1'
            , ProductIsActive: false
            , ProductDescription: ''
            , ProductSmallDescription: ''
            , ProductSearchKeyWord: ''
            , ProductSmallImage: ''
            , VatPer: 0
            , Action: 'ADD'
            , UserID: userDetail && userDetail.UserKey
            , ProImagePath: "Test"
            , BroucherName: ""
            , BroucherPath : ""
            , BroucherData: ""
        });
        //pagekey == formData.ProductKey;
        nav("/ProductMaster");
        setfile('null');
    }
    // function handleChange(newContent ) {
    //     console.log(newContent);
    //     setFormData({
    //         ...formData,
    //         ProductDescription: newContent,
    //     });

    // }
    const handleChange = (e, editorContent = null) => {
        // Check if the first argument is an event object
        if (e && e.target) {
            const name = e.target.name;
            let value = "";
            if (name === "ProductIsActive") {
                value = e.target.checked;
            }
            else if (name === "ProductDescription") {
                setFormData({
                    ...formData,
                    ProductDescription: txtProDescription,
                })
            }
            else {
                value = e.target.value;
            }
            setFormData({
                ...formData,
                [e.target.name]: value,
            });

            // } else {
            //     // It's the content from the editor
            //     setFormData((prevFormData) => ({
            //         ...prevFormData,
            //         ProductDescription: e,
            //     }));
            // }
        };
    }

    const handlefileChange = (e) => {
        const selectedfile = e.target.files[0];
        setfile(selectedfile);
        if (selectedfile && selectedfile.type === 'application/pdf') {
           

            // Create a FileReader to read the selected file as a Base64 encoded string
            const reader = new FileReader();

            reader.onload = (event) => {
                
                const base64Image = event.target.result;
                const base64Data = base64Image.split(',')[1];
                setfile(base64Data);
                setFormData({
                    ...formData,
                    BroucherName: selectedfile.name,
                    BroucherData: base64Data
                });
            };
            // Read the selected file as a Data URL (Base64 encoded)
            reader.readAsDataURL(selectedfile);

        } else {
            swal("Please upload a valid PDF file.", "error");
        }
    };

    const handleClearImage = () => {
        setFormData({
            ...formData,
            BroucherName: '',
            BroucherData: ''
        });
        setfile('');
    };

    //#endregion
    //#region Get Data

    useEffect(() => {
        
        if (formData && formData.BroucherData) {
            setfile(formData.BroucherData);
        } else {
           setfile('');
        }
      }, [formData]);

    useEffect(() => {
        if (pagekey > 0) {
            setIsLoading(true); // Start loading
            setProduct();
            setFormData({ ...formData, ProductKey: pagekey });
            Setbuttonname('Update');
            setshowDelButton(true);

        }
    }, [pagekey]);

    function setProduct() {

        if (pagekey === undefined) {
            return;
        }
        CMS_ProductMasterService.CMS_ProductMasterGet(pagekey)
            .then((res) => {
                if (res.data !== null && res.data.LstProdMember[0].ProductKey > 0) {
                    setFormData(res.data.LstProdMember[0]);
                    setdet(res.data.LstImageMember);
                    setdetdesc(res.data.LstDescMember);
                    settxtProDescription(res.data.LstProdMember[0].ProductDescription);
                   
                }
                else {
                    toast.error("Invalid Job ID!", {
                        position: toast.POSITION.TOP_RIGHT,
                        role: "alert",
                    });
                }

            })
            .catch((err) => {
                toast.error(err.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    role: "alert",
                });
            })
            .finally(() => {
                setIsLoading(false); // Stop loading, whether successful or not
            });
    }
    //#endregion
    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    };

    return (
        <Fragment>
            <Suspense>
                <div className="container-fluid">

                         // Your existing code when data is not loading

                    <div className="row">
                        <div className="col-xl-12 col-xxl-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="col-lg-9">
                                        <h4 className="heading mb-0">Product Master</h4>
                                    </div>


                                    <div className="col-lg-3">
                                        <button type="button" className="btn btn-primary mb-3 mx-1 btn-xs" onClick={() => ProductSave('ADD')}>{buttonname}</button>
                                        {showDelButton &&
                                            <button type="button" className="btn btn-primary mb-3 btn-xs" onClick={() => ProductSave('DELETE')}>Delete</button>}
                                        <button type="button" className="btn btn-primary mb-3 mx-2 btn-xs" onClick={() => handleCancel()}>Cancel</button>

                                    </div>
                                    <h4 className="card-title"></h4>
                                </div>
                                <div className="card-body">
                                    <div className="form-wizard ">
                                        <section>

                                            <div className="row">
                                                <div className="form-group col-lg-2 mb-3">
                                                    <label > Product Code<span className="text-danger">*</span>
                                                    </label>
                                                    <input type="text"
                                                        className="form-control"
                                                        id="ProductCode"
                                                        name="ProductCode"
                                                        value={formData.ProductCode}
                                                        onChange={handleChange}
                                                        placeholder="Product Code" />
                                                </div>
                                                <div className="form-group col-lg-3 mb-3">
                                                    <label > Product Name<span className="text-danger">*</span>
                                                    </label>
                                                    <input type="text"
                                                        className="form-control"
                                                        id="ProductName"
                                                        name="ProductName"
                                                        value={formData.ProductName}
                                                        onChange={handleChange}
                                                        placeholder="Product Name" />
                                                </div>
                                                <div className="form-group col-lg-4 mb-3">
                                                    <label > Category<span className="text-danger">*</span>
                                                    </label>
                                                    <select className="border default-select dropdown-item p-2"
                                                        id="ProductCategoryKey"
                                                        name="ProductCategoryKey"
                                                        value={formData.ProductCategoryKey}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="-1">Select One</option>
                                                        <>
                                                            {Array.isArray(category) && category.map((item) => (
                                                                <option key={item.CategoryKey} value={item.CategoryKey}>{item.CategoryName}</option>
                                                            ))}
                                                        </>
                                                    </select>
                                                </div>
                                                <div className="form-group col-lg-3 mb-3">
                                                    <label > Sub Category<span className="text-danger">*</span>
                                                    </label>
                                                    <select className="border default-select dropdown-item p-2"
                                                        id="ProductSubCategoryKey"
                                                        name="ProductSubCategoryKey"
                                                        value={formData.ProductSubCategoryKey}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="-1">Select One</option>
                                                        <>
                                                            {Array.isArray(subcategory) && subcategory.map((item) => (
                                                                <option key={item.ComboID} value={item.ComboID}>{item.ComboValue}</option>
                                                            ))}
                                                        </>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row">

                                                <div className="form-group col-lg-5 mb-3">
                                                    <label > Product Description<span className="text-danger">*</span>
                                                    </label>
                                                    <textarea type="text"
                                                        cols={9}
                                                        className="form-control"
                                                        id="ProductSmallDescription"
                                                        name="ProductSmallDescription"
                                                        value={formData.ProductSmallDescription}
                                                        onChange={handleChange}
                                                        placeholder="Product Description" />
                                                </div>

                                                <div className="form-group col-lg-2 mb-3">
                                                    <label > keyword<span className="text-danger">*</span>
                                                    </label>
                                                    <input type="text"
                                                        className="form-control"
                                                        id="ProductSearchKeyWord"
                                                        name="ProductSearchKeyWord"
                                                        value={formData.ProductSearchKeyWord}
                                                        onChange={handleChange}
                                                        placeholder="keyword" />
                                                </div>

                                                <div className="form-group col-lg-4 mb-3 row">
                                                    <div className="col-lg-3">
                                                        <label > Vat Per<span className="text-danger">*</span>
                                                        </label>
                                                        <input type="number"
                                                            min={0}
                                                            className="form-control"
                                                            id="VatPer"
                                                            name="VatPer"
                                                            value={formData.VatPer}
                                                            onChange={handleChange}
                                                            placeholder="Vat Per" />
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <label >Status<span className="text-danger mx-3"></span>
                                                        </label>
                                                        <input
                                                            type="checkbox"
                                                            defaultChecked
                                                            className="form-check-input"
                                                            id="ProductIsActive"
                                                            name="ProductIsActive"
                                                            value={formData.ProductIsActive}
                                                            checked={formData.ProductIsActive}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                
                                                    <div className="col-lg-3">
                                                        <label >Broucher<span className="text-danger mx-3"></span></label>
                                                        <div className="form-group mb-3">
                                                            <input type="file" accept=".pdf" onChange={handlefileChange} />
                                                            {file &&
                                                                <>
                                                                    <input type='button'
                                                                        className='btn btn-outline-primary btn-sm form-upload'
                                                                        onClick={() => handleClearImage('DELETE')}
                                                                        value="X"
                                                                    />
                                                                    <a
                                                                        href={formData.BroucherPath}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                    >
                                                                        <img
                                                                            style={{ width: "20px", height: "20px" }}
                                                                            src={'/img/file_pdf.png'}
                                                                            alt={formData.BroucherPath}

                                                                        />
                                                                    </a>
                                                                </>

                                                            }
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                            {formData.ProductKey > 0 &&
                                                <div className="col-lg-12   card ">
                                                    <div className="bg-primary-light card-title p-2">
                                                        Product Information
                                                    </div>
                                                    <div className="card-body">
                                                        {/* Row 2     */}
                                                        <button type="button" className="btn btn-primary mb-3 mx-2 btn-xs" onClick={() => OpenModalDesption()}>Add Description</button>


                                                        <div dangerouslySetInnerHTML={{ __html: txtProDescription }} />
                                                        {/* <div className="full-page-editor">
                                                       <CKEditor
                                                                editor={ClassicEditor}
                                                                id="ProductDescription"
                                                                name="ProductDescription"
                                                                value={formData.ProductDescription}
                                                                data={formData.ProductDescription}
                                                                onReady={editor => {
                                                                    // You can set the data to the editor here as well if it's not already set
                                                                    editor.setData(formData.ProductDescription || '');
                                                                }}
                                                                // onChange={handleEditorChange}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    setFormData({
                                                                        ...formData,
                                                                        ProductDescription: data,
                                                                    });
                                                                }}
                                                                onBlur={(event, editor) => {
                                                                }}
                                                                onFocus={(event, editor) => {
                                                                }}
                                                            /> 
                                                        <Editor
                                                            apiKey='kn2sr71d9is3b9hodgdd91w6s9c62d48ce9tqr25r3a00bpy'
                                                            init={{
                                                                plugins: 'ai tinycomments mentions anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed permanentpen footnotes advtemplate advtable advcode editimage tableofcontents mergetags powerpaste tinymcespellchecker autocorrect a11ychecker typography inlinecss color',
                                                                toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline forecolor | link  table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat ',
                                                                tinycomments_mode: 'embedded',
                                                                tinycomments_author: 'Author name',

                                                                ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
                                                            }}
                                                            initialValue="Welcome to TinyMCE!"
                                                            value={formData.ProductDescription}
                                                            onEditorChange={(newContent) => handleChange(newContent)}
                                                        />


                                                    </div>*/}
                                                    </div>
                                                </div>
                                            }
                                            {formData.ProductKey > 0 &&
                                                <div className="col-lg-12   card ">
                                                    <div className="bg-primary-light card-title p-2">
                                                        Image Upload
                                                    </div>
                                                    <div className="card-body">
                                                        {/* Row 2     */}
                                                        <div className="row">
                                                            <>
                                                                <ProductImageUpload

                                                                    ProductKey={formData.ProductKey}
                                                                    Lstdet={det}

                                                                />
                                                            </>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            {formData.ProductKey > 0 &&
                                                <div className="col-lg-12   card ">
                                                    <div className="bg-primary-light card-title p-2">
                                                        Title & Description
                                                    </div>
                                                    <div className="card-body">
                                                        {/* Row 2     */}
                                                        <div className="row">
                                                            <>
                                                                <Product2Description

                                                                    ProductKey={formData.ProductKey}
                                                                    Lstdescrp={detdesc}

                                                                />
                                                            </>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </section>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </Suspense>
            <EditorModal showModal={showModal} handleCloseModal={handleCloseModal} settxtProDescription={settxtProDescription} prokey={formData.ProductKey} />
        </Fragment>
    );
};

export default ProductMaster;


import http from "./Endpoint";

class UserService {

    UsermasterAutheicate(email,password) {
      debugger
    return http().get(`/App/UsermasterAutheicate?UserName=${email}&UserPassword=${password}`)
  }
}

export default new UserService();
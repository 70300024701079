import http from "./Endpoint";

class CategoryService {
  
  CategorySave(data) {
    
    return http().post("/App/CategorySave", data);
  }
  CategorySearch(data) {
    return http().get(`/App/CategorySearch?sCategoryType=${data.CategoryType}&sCategoryCode=${data.CategoryCode}`);
 
  }

  CategoryGet(catkey) {
  
    return http().get(`/App/CategoryGET?iCategoryKey=${catkey}`);
 
  }

}
export default new CategoryService();
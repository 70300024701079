import React, { useState, Fragment,useEffect } from "react";
import { Link } from 'react-router-dom';
import CMS_ProductMasterService from '../api/CMS_ProductMasterService';
import ComboSerive from "../api/ComboSerive";
import DataTable from 'react-data-table-component';
import { useNavigate } from "react-router-dom";
import { Button, Row, Form } from "react-bootstrap";

const ProductMasterSearch = () => {
    const navigate = useNavigate();
    const [searchData, setsearchData] = useState([]);
    const [category, setcategory] = useState();
    const [subcategory, setsubcategory] = useState();
    //#region combo/auto suggest
    useEffect(() => {
        ComboSerive.FillCategory("ItemGroup")
            .then((response) => {
                setcategory(response.data);
            })
            .catch((error) => {
                console.error("Error fetching combo api:", error);
            });
    }, []);

    useEffect(() => {
        ComboSerive.FillOtherCombo("SubCategoryName", "")
            .then((response) => {
                setsubcategory(response.data);
            })
            .catch((error) => {
                console.error("Error fetching combo api:", error);
            });
    }, []);
    //#endregion

    //#region Search
    const [searchFilter, setsearchFilter] = useState({
        ProductCategoryKey: '-1'
        , ProductSubCategoryKey: '-1'
        , ProductName: ''
    });

    useEffect(() => {
        CMS_ProductMasterService.CMS_ProductMasterSearch(searchFilter)
            .then((response) => {

                setsearchData(response.data);

            })
            .catch((error) => {
                console.error("Error fetching product search:", error);
            });


    }, []);
    const ProductSearch = (e) => {
        e.stopPropagation();
        e.preventDefault();

        CMS_ProductMasterService.CMS_ProductMasterSearch(searchFilter)
            .then((response) => {
                // console.success("category search successfully", success);
                setsearchData(response.data);

            })
            .catch((error) => {
                console.error("Error fetching product search:", error);
            });

    };
    //#endregion
    function handleChange(evt) {

        const name = evt.target.name;
        let value = evt.target.value;
        setsearchFilter({
            ...searchFilter,
            [evt.target.name]: value,
        });
    }
    //#region GRID
  
    const columns = [
      {
        button: true,
        width: "5rem", // Set the width to 150 pixels
        //cell:(row)=><button onClick={clickHandler} id={row.ID}>Action</button>
        cell: (row) => (
          <div className="openbtn">
            <button
              type="button"
              className="btn btn-info btn-sm"
         
              onClick={() => open(row.ProductKey)}
            >
              select
            </button>
  
          </div>
        ),
  
      },
      // {
      //   name: "No.",
      //   selector: (row) => row.id,
      //   sortable: true,
      //   width: "7rem", // Set the width to 150 pixels
      // },
      {
        name: "Product Name",
        selector: (row) => row.ProductName,
        sortable: true,
        width: "20rem", // Set the width to 150 pixels
      },
      {
        name: "Category",
        selector: (row) => row.CategoryName,
        sortable: true,
        width: "20rem", // Set the width to 150 pixels
      },
      
      {
        name: "Sub Category",
        selector: (row) => row.SubCategoryName,
        sortable: true,
        width: "20rem", // Set the width to 150 pixels
      },
      {
        name: "Image",
        sortable: true,
  
        cell: (row) => (
          <a href="#"
            id="CPH_ctrTransactions_dlTranDetail_hreflink_1" target="_blank">
            <img
              style={{ width: "100px", height: "100px" }}
              src={row.ProImagePath ? row.ProImagePath : '/img/noimages.png'}
              alt=""
            // onError={handleImageError}
            />
          </a>
  
        ),
      },
      {
        name: "Status",
        selector: (row) => row.ProductIsActive == 1 ?"Active":"Inctive",
        sortable: true,
        width: "10rem", // Set the width to 150 pixels
      },
     
  
    ];
  
    //#endregion
    const open = (key) => {
      navigate('/ProductMaster/' + key)
    }
    const handleImageError = (e) => {
      e.target.src = '/img/noimages.png'; // Provide the path to your default or empty image
    };
    return (
      <Fragment>
        {/* <MainPagetitle mainTitle="Finance" pageTitle={'Finance'}  parentTitle={'Home'} /> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12 ">
              <div className="card h-auto">
                <div className="card-header">
                  <h4 className="heading mb-0">Product Search </h4>
                </div>
                <div className="card-body">
                  <div className="col-xl-12 col-xxl-12">
                    <div className="card">
                      <div className="col-lg-12 row p-2">
                        <Row className="col-lg-4">
                          <div
                            lg={3}
                            className="align-self-sm-center or-form-label mb-1 mb-lg-0"
                          >
                            Product Name
                          </div>
                          <div lg={5} className="">
                            <Form.Control
                              className="form-control"
                              id="ProductName"
                              name="ProductName"
                              value={searchFilter.ProductName}
                              onChange={handleChange}
                              placeholder="Product Name"
                            >
                            </Form.Control>
                          </div>
                        </Row>
                        <Row className="col-lg-4">
                          <div
                            lg={3}
                            className="align-self-sm-center or-form-label mb-1 mb-lg-0"
                          >
                           Category:
                          </div>
                          <div lg={5} className="">
                            <select
                              className="border default-select dropdown-item p-2"
                              id="ProductCategoryKey"
                              name="ProductCategoryKey"
                              value={searchFilter.ProductCategoryKey}
                              onChange={handleChange}
                            >
                             <option value="-1">Select All</option>
                            {Array.isArray(category) && category.map((item) => (
                                                <option key={item.CategoryKey} value={item.CategoryKey}>{item.CategoryName}</option>
                                            ))}
                            </select>
                          </div>
                        </Row>
                        <Row className="col-lg-3">
                          <div
                            lg={3}
                            className="align-self-sm-center or-form-label mb-1 mb-lg-0"
                          >
                            Sub Category:
                          </div>
                          <div lg={5} className="">
                            <select
                              className="border default-select dropdown-item p-2"
                              id="ProductSubCategoryKey"
                              name="ProductSubCategoryKey"
                              value={searchFilter.ProductSubCategoryKey}
                              onChange={handleChange}
                            >
                               <option value="-1">Select All</option>
                                {Array.isArray(subcategory) && subcategory.map((item) => (
                                        <option key={item.ComboID} value={item.ComboID}>{item.ComboValue}</option>
                                    ))}
                            </select>
  
                          </div>
                        </Row>
                      
                        <Row className="col-lg-1">
                          <div
                            lg={3}
                            className="d-flex justify-content-between d-none d-lg-flex"
                          >
                            &nbsp;
                          </div>
                          <div lg={9} className="d-flex justify-content-between">
                            <div className="d-flex gap-3 flex-wrap">
                              <Button className="button-product h-auto px-3 px-lg-4 lh-sm" onClick={ProductSearch}>
                                Search
                              </Button>
  
                            </div>
                          </div>
                        </Row>
                      </div>
                      <div className="table-responsive col-lg-12" style={{ overflowX: 'auto', maxHeight: '400px' }}>
                        <DataTable
                          title=""
                          columns={columns}
                          data={searchData}
                          defaultSortFieldID={1}
                          pagination
  
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
          </div>
        </div>
      </Fragment>
    );
  }

export default ProductMasterSearch;
import React, { useEffect } from 'react'
import { Modal,Col, Card } from "react-bootstrap";

const EditorModal = ({ showModal, handleCloseModal, settxtProDescription, prokey }) => {
  
  useEffect(() => {
    // Event listener for receiving message from the iframe
    const receiveMessage = (event) => {
      // Validate the origin of the message
      // if (event.origin !== "http://localhost:5187") {
      if (event.origin !== "http://easycoolapi.pos-erp.in") {
        return;
      }

      // Handle the message received from the iframe
      if (event.data.type === 'remarks') {
        settxtProDescription(event.data.value);
        handleCloseModal();
      }
    };

    // Listen for messages sent from the iframe
    window.addEventListener('message', receiveMessage);

    // Cleanup listener
    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, []);

  return (
    <div>
      <Modal show={showModal} onHide={handleCloseModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title style={{ "color": "#2950a3" }}>Product Description</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>

            <Card.Body>
              <Col className="pb-4 order-report-form small">

                <iframe src={"http://easycoolapi.pos-erp.in/Editor?id="+prokey} title="ABC Content" id="frameShortcut" style={{ width: '100%', height: '800px' }}></iframe>
             
              </Col>

            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default EditorModal;
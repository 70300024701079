import http from "./Endpoint";

class CMS_ProductMasterService {
  
  // CMS_ProductMasterSave(data) {
  //   
  //   return http().post("/CMS/CMS_ProductMasterSave", data);
  // }

  CMS_ProductMasterSave(formData) {
    
    return http().post("/CMS/CMS_ProductMasterSave", {
      ProductKey: formData.ProductKey,
      ProductCode: formData.ProductCode,
      ProductName: formData.ProductName,
      ProductCategoryKey: formData.ProductCategoryKey,
      ProductSubCategoryKey: formData.ProductSubCategoryKey,
      ProductIsActive: formData.ProductIsActive,
      ProductDescription: formData.ProductDescription,
      ProductSmallDescription: formData.ProductSmallDescription,
      ProductSearchKeyWord: formData.ProductSearchKeyWord,
      ProductSmallImage: formData.ProductSmallImage,
      VatPer: formData.VatPer,
      BroucherName: formData.BroucherName,
      BroucherData: formData.BroucherData,
      UserID: formData.UserID,
      Action: formData.Action,
      
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
  }
  CMS_ProductMasterGet(ProductKey) {
    return http().get(`/CMS/CMS_ProductMasterGet?ProductKey=${ProductKey}`);
  }

  CMS_ProductMasterSearch(data) {
    return http().get(`/CMS/CMS_ProductMasterSearch?ProductCategoryKey=${data.ProductCategoryKey}&ProductSubCategoryKey=${data.ProductSubCategoryKey}&ProductName=${data.ProductName}`);
  }
  CMS_Product2ImageSave(formData) {
    return http().post("/CMS/CMS_Product2ImageSave", {
      ID: formData.ID,
      ImageData: formData.ImageData,
      FileName: formData.FileName,
      ProductKey: formData.ProductKey,
      Action: formData.Action,
      ContentType:formData.ContentType,

      
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
  }

  CMS_Product2descriptionSave(data) {
    return http().post("/CMS/CMS_Product2descriptionSave", data);
  }

}
export default new CMS_ProductMasterService();
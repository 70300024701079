import React, { useEffect, useState } from 'react';
import { getProfile } from '../../services/AuthService';
import { toast } from "react-toastify";

export function byteArrayToImageUrl(byteArray) {
    if(byteArray!=null){
        const uint8Array = new Uint8Array(byteArray.match(/[\da-f]{2}/gi).map((hex) => parseInt(hex, 16)));
        const blob = new Blob([uint8Array], { type: 'image/jpeg' });
        const imageUrl = URL.createObjectURL(blob);
        return imageUrl;
    }
    else{
        return '';
    }
}
export const GetUserDetail = () => {
    const userDetailJSON = getProfile(); // Assuming getProfile() is a function that retrieves the JSON string
    const userDetail = JSON.parse(userDetailJSON);
  
    // Check if userDetail or userDetail.userkey is null and handle it accordingly
    if (!userDetail || userDetail.userkey === null) {
      // You can return a default value or handle the error as needed
      return null;
    }
  
    return userDetail;
  };
  // export const GetUserDetail = () => {
  //   const userDetailJSON = getProfile();
  //   const [userDetail, setUserDetail] = useState(JSON.parse(userDetailJSON));
   
  //   useEffect(() => {
    
  //       // You can return a default value or handle the error as needed
  //       setUserDetail(userDetailJSON);
      
    
  //   }, []);
  
  //   return userDetail;
  // };
  
  export function GETMMDDYYYY(inputDate) {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  export const generateRandomNumber = () => {
    const min = 100000000000000000;  // minimum range
    const max = 999999999999999999;  // maximum range
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomNumber.toString();
  };

  export const ShowToast = (message, logged) => {
    if (message.includes("Success")) {
      toast.success(logged, {
        position: toast.POSITION.TOP_RIGHT,
        role: "alert",
      });
    }
    else if (message.includes("Error")) {
      toast.error(logged, {
        position: toast.POSITION.TOP_RIGHT,
        role: "alert",
      });
    }
  }
  
  

import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CMS_ClientMasterService from "../api/CMS_ClientMasterService";
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import ClientMasterSearch from './ClientMasterSearch';
import { toast } from "react-toastify";
import { generateRandomNumber } from '../../jsx/Common/Commonentry';
const ClientMaster = () => {
  const [refresh, setRefresh] = useState(false);
  let { pagekey } = useParams();
  const nav = useNavigate();
  const [image, setImage] = useState('');
  const [formData, setFormData] = useState({
    ID: 0
    , ClientName: ''
    , CompanyName: ''
    , ImageName: ''
    , ImageByte: ''
    , Action: "ADD",
  });

  useEffect(() => {

    document.title = 'Client Master'; // Set your desired page title here
    handleCancel();
    setRefresh(false);
  }, [refresh]);

  const [buttonname, Setbuttonname] = useState('ADD');
  const [showDelButton, setshowDelButton] = useState(false);

  //#region Save Data
  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setImage(selectedImage);

      // Extract the file extension
      const fileExtension = selectedImage.name.split('.').pop();

      // Concatenate the file name and extension
      const fullFileName = formData.ImageName + '.' + fileExtension;
  

    // Create a FileReader to read the selected image as a Base64 encoded string
    const reader = new FileReader();

    reader.onload = (event) => {
      // Set the Base64 encoded image string in state
      const base64Image = event.target.result;
      const base64Data = base64Image.split(',')[1];
      setImage(base64Data);
      setFormData({
        ...formData,
        ImageName: fullFileName,
        ImageByte: base64Data
      });

    };

    // Read the selected image as a Data URL (Base64 encoded)
    reader.readAsDataURL(selectedImage);
  };


  const Save = (sAction) => {

    // if (image) {

      let errorMessage = "";

      try {
        
        if (!formData.CompanyName) {
          errorMessage += "Please enter Company Name.<br>";
        }
        // if (!formData.ClientName) {
        //   errorMessage += "Please enter Place.<br>";
        // }
        if (!image) {
          errorMessage += "Please select Image for client.<br>";
        }

        if (sAction === "DELETE") {
          formData.Action = 'DELETE';
        }
        else {
          if (pagekey > 0) {
            formData.Action = 'UPDATE';
          }
          else {
            formData.Action = 'ADD';
          }
        }
        if (errorMessage !== "") {

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: errorMessage,
          });
        }
        else {

          CMS_ClientMasterService.CMS_ClientMasterSave(formData)
            .then((response) => {

              swal("Client Master Save Successfully", "", "success");
              handleCancel();
              setRefresh(true);
            })
            .catch((error) => {
              swal("Error occurs while saving Client Master Save.", "error");
            });

        }
      } catch (error) {
        swal("Error occurs while saving Client Master Save.", error);
      }
    // }
  };
  const handleCancel = () => {
    Setbuttonname('ADD');
    setshowDelButton(false);
    setImage('');
    setFormData({
      ID: 0
      , ClientName: ''
      , CompanyName: ''
      , ImageName: generateRandomNumber()
      , ImageByte: ''
      , Action: "ADD",
    });
    
    nav("/ClientMaster");

  }
  function handleChange(evt) {
    setFormData({
      ...formData,
      [evt.target.name]: evt.target.value,
    });

  }
  //#endregion

  //#region Get Data
  useEffect(() => {

    if (formData && formData.ImageByte) {
      setImage(formData.ImageByte);
    } else {
      setImage('');
    }
  }, [formData])

  useEffect(() => {
    if (pagekey > 0) {

      setClientMaster();
      setFormData({ ...formData, ID: pagekey });
      Setbuttonname('Update');
      setshowDelButton(true);

    }
  }, [pagekey]);
  function setClientMaster() {

    if (pagekey === undefined) {
      return;
    }
    CMS_ClientMasterService.CMS_ClientMasterGET(pagekey)
      .then((res) => {
        if (res.data !== null && res.data.ID > 0) {
          setFormData(res.data);
        }
        else {
          toast.error("Invalid Job ID!", {
            position: toast.POSITION.TOP_RIGHT,
            role: "alert",
          });
        }
      })
      .catch((err) => {
        toast.error(err.message, {
          position: toast.POSITION.TOP_RIGHT,
          role: "alert",
        });
      });
  }
  //#endregion
  return (
    <Fragment>
      {/* <MainPagetitle mainTitle="Finance" pageTitle={'Finance'}  parentTitle={'Home'} /> */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-3 col-xxl-4">
            <div className="card h-auto">
              <div className="card-header">
                <h4 className="heading mb-0">Client Entry </h4>
              </div>
              <div className="card-body">
                <form className="finance-hr" >
                  

                  <div className="row">
                    <div className="form-group col-lg-12 mb-3">
                      <label >Company Name<span className="text-danger">*</span>
                      </label>
                      <input ClientName="text"
                        className="form-control form-txtarea"
                        id="CompanyName"
                        name="CompanyName"
                        value={formData.CompanyName}
                        onChange={handleChange}
                        placeholder="Company Name" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group  col-lg-12 mb-3">
                      <label>Place<span className="text-danger">*</span>
                      </label>
                      <input ClientName="text"
                        className="form-control form-txtarea"
                        id="ClientName"
                        name="ClientName"
                        value={formData.ClientName}
                        onChange={handleChange}
                        placeholder="Place" />
                    </div>
                  </div>
                  <div className="row">
                  <div className="form-group mb-3">
                    <input type="file" accept="image/*" onChange={handleImageChange} />
                    {/* <input type='button'
                      className='btn btn-outline-primary btn-sm form-upload mx-1'
                      onClick={() => Save('ADD')}
                      value="Upload"
                    /> */}

                    {formData.ID > 0 &&
                      <input type='button'
                        className='btn btn-outline-primary btn-sm form-upload'
                        onClick={() => Save('DELETE')}
                        value="X"
                      />
                    }
                    {image &&
                      <img src={`data:image/png;base64,${image}`}
                        alt="Uploaded"
                        width="100" />}

                  </div>
                  </div>
                  <button type="button" className="btn btn-primary mb-3 mx-1" onClick={() => Save('ADD')}>{buttonname}</button>
                  {showDelButton &&
                    <button type="button" className="btn btn-primary mb-3" onClick={() => Save('DELETE')}>Delete</button>}
                  <button type="button" className="btn btn-primary mb-3 mx-2" onClick={() => handleCancel()}>Cancel</button>

                </form>
              </div>
            </div>
          </div>
          <div className="col-xl-9 col-xxl-8">
            <ClientMasterSearch isRefresh={refresh}></ClientMasterSearch>

          </div>
        </div>
      </div>
    </Fragment >
  );
};

export default ClientMaster;
// import MainPagetitle from '../../layouts/MainPagetitle';
import React, { useState, useEffect, Fragment, Suspense, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import UserRoleService from "../api/UserRoleService";
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import { toast } from "react-toastify";
import { getProfile } from '../../services/AuthService';
import ComboSerive from "../api/ComboSerive";

const UserPermission = () => {

    let { roleid } = useParams();
    const [refresh, setRefresh] = useState(false);
    const userDetail = JSON.parse(getProfile());
    const [buttonname, Setbuttonname] = useState('Update');
    const [module, setmodule] = useState();
    const [searchData, setsearchData] = useState([]);
    const [refgrid, setrefgrid] = useState(false);
    const [formData, setFormData] = useState({
        ModuleID: 0
        , XML: []
        , RoleId: 0
        , UserID: userDetail && userDetail.UserKey

    });
    useEffect(() => {
        document.title = 'User Permission'; // Set your desired page title here
       
        setRefresh(false);
    }, [refresh]);
    //#region combo/auto suggest
    useEffect(() => {
        ComboSerive.FillOtherCombo("SystemModules", "")
            .then((response) => {
                setmodule(response.data);
            })
            .catch((error) => {
                console.error("Error fetching combo api:", error);
            });
    }, []);


    //#endregion
    //#region Save Data
    const PermUpdate = (sAction) => {

        let errorMessage = "";

        if (!formData.ModuleID) {
            errorMessage += "Please select Module.<br>";
        }

        
        else {
            if (roleid > 0) {
                formData.Action = 'UPDATE';
            }
            else {
                formData.Action = 'ADD';
            }
        }
        formData.UserID = userDetail && userDetail.UserKey != null ? userDetail.UserKey : 1;
        // setFormData({ ...formData, ListXML: searchData });
        formData.RoleId = roleid && roleid;
        if (errorMessage !== "") {

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                html: errorMessage,
            });
        }
        else {
            
            UserRoleService.PermissionUpdate(formData)
                .then((response) => {

                    swal("Permission Save Successfully", "", "success");
                 
                    setRefresh(true);

                })
                .catch((error) => {
                    swal("Error occurs while saving Permission Save.", "error");
                });

        }
    };
    const handleCancel = () => {
        Setbuttonname('Update');
        setFormData({
            ModuleID: 0
            , XML: []
            , RoleId: ''
            , UserID: userDetail && userDetail.UserKey
        });
    }
    function handleChange(evt) {
        evt.stopPropagation();
        evt.preventDefault();
        if(evt.target.name==="ModuleID"){
            setrefgrid(true);
        }
        setFormData({
            ...formData,
            [evt.target.name]: evt.target.value,
        });
    }
    //#endregion
    //#region Get Data
 
    useEffect(() => {
 
            setPermission();
            console.log(searchData);
            setrefgrid(false);
     
    }, [formData.ModuleID,refgrid]);

    function setPermission() {

        if (roleid === undefined) {
            return;
        }
        UserRoleService.PermissionGet(roleid)
            .then((res) => {
                console.warn('call....');
                if (res.data !== null && Array.isArray(res.data)) {
                    console.log(formData.ModuleID);
                    const filteredContent = res.data.filter((x => x.ModuleID === parseInt( formData.ModuleID )));
                    setsearchData(filteredContent);
                    
                  }
                  else {
                    setsearchData([]);
                  }
            })
            .catch((err) => {
                toast.error(err.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    role: "alert",
                });
            });

    }
    //#endregion

    const [detdata, setDetdata] = useState({
        PermissionKey: 0
        , MenuID: 0
        , Add: false
        , Edit: false
        , Delete: false
        , View: false
        , ViewLog: false
        , Print: false
    });
    const handleDetail = (index, field, event) => {
        setrefgrid(false);
        // Determine the value based on the event target type
        const value = event.target.type === 'checkbox' ? event.target.checked ? true : false : event.target.value;
        
        // Update the data in state
        const newXML = [...searchData];
        newXML[index][field] = value;
        
        
        setsearchData(newXML);
        setFormData({ ...formData, ListXML: newXML });
    };
    return (
        <Fragment>
            <Suspense>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12 col-xxl-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="col-lg-9">
                                        <h4 className="heading mb-0">User Permission</h4>
                                    </div>


                                    <div className="col-lg-3">
                                        <button type="button" className="btn btn-primary mb-3 mx-2 btn-xs" onClick={() => PermUpdate('ADD')}>{buttonname}</button>
                                        
                                    </div>
                                    <h4 className="card-title"></h4>
                                </div>
                                <div className="card-body">
                                    <div className="form-wizard ">
                                        <section>
                                            <div className="row">
                                                <div className="form-group col-lg-2 mb-3">
                                                    <label > Module ID<span className="text-danger">*</span>
                                                    </label>
                                                    <select className="border default-select dropdown-item p-2"
                                                        id="ModuleID"
                                                        name="ModuleID"
                                                        value={formData.ModuleID}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="-1">Select One</option>
                                                        <>
                                                            {Array.isArray(module) && module.map((item) => (
                                                                <option key={item.ComboID} value={item.ComboID}>{item.ComboValue}</option>
                                                            ))}
                                                        </>
                                                    </select>
                                                </div>
                                            </div>
                                            {searchData &&
                                                <div className="table-active">

                                                    <div id="finance-tblwrapper" className="dataTables_wrapper" >
                                                        <table id="empoloyees-tbl1" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>MenuName</th>
                                                                    <th>Add</th>
                                                                    <th>Edit</th>
                                                                    <th>Delete</th>
                                                                    <th>View</th>
                                                                  
                                                                    <th>Print</th>
                                                                    <th className='hidden'>ViewLog</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {searchData.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td>{item.MenuName}</td>
                                                                        <td>
                                                                            <input
                                                                                type="checkbox"
                                                                                id="Add"
                                                                                name="Add"
                                                                                value={item.Add}
                                                                                checked={item.Add}
                                                                                onChange={(e) => handleDetail(index, 'Add', e)}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                type="checkbox"
                                                                                id="Edit"
                                                                                name="Edit"
                                                                                value={item.Edit}
                                                                                checked={item.Edit}
                                                                                onChange={(e) => handleDetail(index, 'Edit', e)}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                type="checkbox"
                                                                                id="Delete"
                                                                                name="Delete"
                                                                                value={item.Delete}
                                                                                checked={item.Delete}
                                                                                onChange={(e) => handleDetail(index, 'Delete', e)}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                type="checkbox"
                                                                                id="View"
                                                                                name="View"
                                                                                value={item.View}
                                                                                checked={item.View}
                                                                                onChange={(e) => handleDetail(index, 'View', e)}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                type="checkbox"
                                                                                id="Print"
                                                                                name="Print"
                                                                                value={item.ProductIsActive}
                                                                                checked={item.ProductIsActive}
                                                                                onChange={(e) => handleDetail(index, 'Print', e)}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                type="checkbox"
                                                                                id="ViewLog"
                                                                                name="ViewLog"
                                                                                className='hidden'
                                                                                value={item.ViewLog}
                                                                                checked={item.ViewLog}
                                                                                onChange={(e) => handleDetail(index, 'ViewLog', e)}
                                                                            />
                                                                        </td>
                                                                       
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </div>
                                            }
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </Suspense>
        </Fragment>
    );
};

export default UserPermission;


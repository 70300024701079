// import MainPagetitle from '../../layouts/MainPagetitle';
import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CMS_Category2ImageService from "../api/CMS_Category2ImageService";
import ComboSerive from "../api/ComboSerive";

const Category2ImageSearch = ({isRefresh}) => {
    const nav = useNavigate();
    const [category, setcategory] = useState();
    const [searchData, setsearchData] = useState([]);
     //#region combo/auto suggest
    useEffect(() => {
        ComboSerive.FillOtherCombo("CategoryName", "")
        .then((response) => {
            setcategory(response.data);
        })
        .catch((error) => {
            console.error("Error fetching combo api:", error);
        });
    }, []);
  //#endregion
    //#region Search
    const [searchFilter, setsearchFilter] = useState({
        CategoryKey: '-1'
    });

    useEffect(() => {
        CMS_Category2ImageService.CMS_Category2ImageSearch(searchFilter)
            .then((response) => {
                
                setsearchData(response.data);
               
            })
            .catch((error) => {
                console.error("Error fetching category search:", error);
            });


    }, [isRefresh]);
    const Category2ImageSearch = (e) => {
        e.stopPropagation();
        e.preventDefault();

        CMS_Category2ImageService.CMS_Category2ImageSearch(searchFilter)
            .then((response) => {
                // console.success("category search successfully", success);
                setsearchData(response.data);

            })
            .catch((error) => {
                console.error("Error fetching category search:", error);
            });

    };
    //#endregion
    function handleChange(evt) {
        
        const name = evt.target.name;
        let value = evt.target.value;
        setsearchFilter({
            ...searchFilter,
            [evt.target.name]: value,
        });
    }
    return (
        <>
            <div className="card">
                <div className='col-lg-12 p-3 row'>
                    <div className="form-group col-lg-3">
                        <label > Category<span className="text-danger">*</span>
                        </label>
                        <select className="border default-select dropdown-item p-2"
                            id="CategoryKey"
                            name="CategoryKey"
                            value={searchFilter.CategoryKey}
                            onChange={handleChange}
                        >
                            <option value="-1">Select All</option>
                            {Array.isArray(category) && category.map((item) => (
                                <option key={item.ComboID} value={item.ComboID}>{item.ComboValue}</option>
                            ))}
                           
                        </select>
                    </div>
                    
                    <div className="form-group col-lg-1">
                        <label></label>
                        <button type="submit" className="btn btn-primary mb-3" onClick={Category2ImageSearch}>Search</button>
                    </div>
                </div>

                <div className="card-body p-0">
                    <div
                        className="table-active table-responsive"
                        style={{ overflowX: "auto", maxHeight: "400px" }}
                    >
                        <div className="table-responsive active-projects manage-client">
                            <div id="finance-tblwrapper" className="dataTables_wrapper no-footer">
                                <table id="empoloyees-tbl1" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                    <thead>
                                        <tr>
                                            <th>Category</th>
                                            <th>Image</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {searchData.map((item, index) => (
                                            <tr key={item.CategoryKey}>
                                                <td>
                                                    <Link to={"/Category2Image/" + item.ID} className="text-primary">{item.CategoryName}</Link>
                                                </td>
                                                <td>
                                                    <div className="card card-body border-dark">
                                                        <img
                                                            style={{ width: "100px", height: "100px" }}
                                                            src={item.ImagePath ? item.ImagePath : '/img/noimages.png'}
                                                            alt=""
                                                        // onError={handleImageError}
                                                        />
                                                    </div>
                                                </td>
                                                <td></td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Category2ImageSearch;
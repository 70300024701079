// import MainPagetitle from '../../layouts/MainPagetitle';
import React, { useState,  useEffect, Fragment } from 'react';
import {  useNavigate,useParams } from 'react-router-dom';
import CategoryService from "../api/CategoryService";
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import CategorySearch from './CategorySearch';
import {Encategory} from '../Common/Enum';
import { toast } from "react-toastify";
import { getProfile } from '../../services/AuthService';

const CategoryEntry = () => {
    const[refresh,setRefresh]=useState(false);
    let {pagekey}= useParams();
    const nav = useNavigate();
    const userDetail = JSON.parse(getProfile());
    const [formData, setFormData] = useState({
        CategoryKey: 0
        , CategoryType: '-1'
        , CategoryName: ''
        , OtherValue: ''
        , CategoryCode: ''
        , CategoryStatus: false
        , Action: ''
        , UserID: userDetail.UserKey
    });
   
    useEffect(() => {
        
        document.title = 'Category Master'; // Set your desired page title here
        handleCancel();
        setRefresh(false);
    }, [refresh]);
    
    const [buttonname, Setbuttonname] = useState('ADD');
    const [showDelButton, setshowDelButton] = useState(false);

    //#region Save Data
    const CategorySave = (sAction) => {
      
        let errorMessage = "";
        if (formData.CategoryType === "-1") {
            errorMessage += "Please select Category Type.<br>";
        }
        if (!formData.CategoryName) {
            errorMessage += "Please enter Category Name.<br>";
        }
        
        if (sAction === "DELETE") {
            formData.Action = 'DELETE';
        }
        else {
            if (pagekey > 0) {
                formData.Action = 'UPDATE';
            }
            else {
                formData.Action = 'ADD';
            }
        }
        formData.UserID=userDetail.UserKey != null ? userDetail.UserKey : 1;
        if (errorMessage !== "") {

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                html: errorMessage,
            });
        }
        else {
             
            CategoryService.CategorySave(formData)
                .then((response) => { 
            
                    swal("Category Save Successfully", "", "success");
                    handleCancel();
                    setRefresh(true);
                })
                .catch((error) => {
                    swal("Error occurs while saving Category Save.", "error");
                });

        }
    };
    const handleCancel = () => {
        Setbuttonname('ADD');
        setshowDelButton(false);
        setFormData({
             CategoryKey: 0
            , CategoryType: '-1'
            , CategoryName: ''
            , OtherValue: ''
            , CategoryCode: ''
            , CategoryStatus: false
            , Action: 'ADD'
            , UserID: userDetail && userDetail.UserKey
        });
        nav("/CategoryEntry");

    }
    function handleChange(evt) {
        
        const name = evt.target.name;
        let value = "";
        if (name === "CategoryStatus") {
            
            value = evt.target.checked;
        }
        else {
            value = evt.target.value;
        }
        setFormData({
            ...formData,
            [evt.target.name]: value,
        });
    }
    //#endregion

    //#region Get Data
    useEffect(() => {
        if (pagekey > 0) {
            
            setcategory();
            setFormData({ ...formData, CategoryKey: pagekey });
            Setbuttonname('Update');
            setshowDelButton(true);
            
        }
      }, [pagekey]);
      function setcategory(){
        
        if(pagekey===undefined){
          return;
        }
        CategoryService.CategoryGet(pagekey)
        .then((res) => { 
          if (res.data!==null && res.data.CategoryKey>0) {
            setFormData(res.data);
          } 
          else {
            toast.error("Invalid Job ID!", {
              position: toast.POSITION.TOP_RIGHT,
              role: "alert",
            });
          }
    
        })
        .catch((err) => {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
            role: "alert",
          });
        });
      }
    //#endregion
    return (
        <Fragment>
            {/* <MainPagetitle mainTitle="Finance" pageTitle={'Finance'}  parentTitle={'Home'} /> */}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-3 col-xxl-4">
                        <div className="card h-auto">
                            <div className="card-header">
                                <h4 className="heading mb-0">Category Entry </h4>
                            </div>
                            
                            <div className="card-body">
                                <form className="finance-hr" >

                                    <div className="form-group mb-3">
                                        <label > Category Type<span className="text-danger">*</span>
                                        </label>
                                        <select className="border default-select dropdown-item p-2"
                                            id="CategoryType"
                                            name="CategoryType"
                                            value={formData.CategoryType}
                                            onChange={handleChange}
                                        >
                                            <option value="-1">Select One</option>
                                            {Encategory.map((item) => (
                                                <option key={item.label}>{item.value}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label > Category Name<span className="text-danger">*</span>
                                        </label>
                                        <input type="text"
                                            className="form-control"
                                            id="CategoryName"
                                            name="CategoryName"
                                            value={formData.CategoryName}
                                            onChange={handleChange}
                                            placeholder="Category Name" />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label > Description<span className="text-danger">*</span>
                                        </label>
                                        <textarea   type="text"
                                                className="form-txtarea form-control"
                                            id="OtherValue"
                                            name="OtherValue"
                                            rows="5"
                                            value={formData.OtherValue}
                                            onChange={handleChange}
                                            placeholder="Description" />
                                    </div>
                               
                                    <div className="form-group mb-3">
                                        <label >Status<span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="checkbox"
                                            defaultChecked
                                            className="form-check-input"
                                            id="CategoryStatus"
                                            name="CategoryStatus"
                                            value={formData.CategoryStatus}
                                            checked={formData.CategoryStatus}
                                            onChange={handleChange}
                                            required
                                        />
                                        
                                    </div>
                                  
                                    <button type="button" className="btn btn-primary mb-3 mx-2" onClick={() => CategorySave('ADD')}>{buttonname}</button>
                                    {showDelButton &&
                                        <button type="button" className="btn btn-primary mb-3" onClick={() => CategorySave('DELETE')}>Delete</button>}
                                    <button type="button" className="btn btn-primary mb-3 mx-2" onClick={() => handleCancel()}>Cancel</button>

                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-9 col-xxl-8">
                        <CategorySearch isRefresh ={refresh}></CategorySearch>
                       
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default CategoryEntry;
import React, { useState, useEffect, Fragment, useRef, Suspense } from 'react';
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import { Link, useNavigate } from 'react-router-dom';
import CMS_ProductMasterService from "../api/CMS_ProductMasterService";
import ProgressBar from 'react-bootstrap/ProgressBar';
const ProductImageUpload = ({ ProductKey, Lstdet }) => {

  const nav = useNavigate();
  const [searchData, setsearchData] = useState([]);
  const [image, setImage] = useState('');
  const fileInputRef = useRef(null);
  const [detailData, setDetailData] = useState({
    Index: 0,
    ID: 0,
    ImageData: null,
    FileName: '',
    ProductKey: ProductKey,
    ContentType:'-1',
    Action: "ADD",
  });
  useEffect(() => {
    if (ProductKey > 0) {
      setsearchData(Lstdet);
    }
    else {
      handleCancel();
    }
  }, [ProductKey]);


  const handleCancel = () => {
    setImage(null);
    fileInputRef.current.value = null;

    setDetailData({
      Index: 0,
      ID: 0,
      ImageData: null,
      FileName: '',
      ProductKey: ProductKey,
      ContentType:'-1',
      Action: "ADD",
    });
    nav("/ProductMaster/" + ProductKey);
    if (ProductKey === 0) {
      setsearchData([]);
    }
    // nav("/PDFDownload");
  }
  //#region Save 
  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setImage(selectedImage);

    // Create a FileReader to read the selected image as a Base64 encoded string
    const reader = new FileReader();

    reader.onload = (event) => {
      // Set the Base64 encoded image string in state
      const base64Image = event.target.result;
      const base64Data = base64Image.split(',')[1];
      setImage(base64Data);
      setDetailData({
        ...detailData,
        FileName: selectedImage.name,
        ImageData: base64Data
      });
    };

    // Read the selected image as a Data URL (Base64 encoded)
    reader.readAsDataURL(selectedImage);
  };
  const handleChange = (e) => {
    setDetailData({
      ...detailData,
      [e.target.name]: e.target.value,
    });
  };


  const SaveImage = (sAction) => {
    debugger
    let errorMessage = "";
    if (detailData.ImageData) {
      try {

        if (detailData.ContentType === "-1") {
          errorMessage += "Please select Type.<br>";
        }

        let action;
        if (sAction === 'DELETE') {
          action = 'DELETE';
        } else {
          // const index = detailData.Index;
          //searchData.findIndex(item => item.Index === detailData.Index);

          action = detailData.ID > 0 ? 'UPDATE' : 'ADD';
        }
        const updatedData = { ...detailData, Action: action };
        if (errorMessage !== "") {

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: errorMessage,
          });
        }
        else {
        CMS_ProductMasterService.CMS_Product2ImageSave(updatedData)
          .then((response) => {
            setsearchData((prevData) => {
              if (action === 'DELETE') {
                return prevData.filter(item => item.ID !== updatedData.ID);
              } else {
                const index = prevData.findIndex(item => item.ID === updatedData.ID);
                if (index > -1) {
                  const newSearchData = [...prevData];
                  newSearchData[index] = updatedData;
                  return newSearchData;
                } else {
                  return [...prevData, updatedData];
                }
              }
            });
            // setsearchData(prevData => {
            //   const existingIndex = prevData.findIndex(item => item.Index === updatedData.index);
            //   
            //   if (existingIndex !== -1) {
            //     // Update existing item
            //     const newData = [...prevData];
            //     newData[existingIndex] = updatedData;
            //     return newData;
            //   } else {
            //     // Add new item
            //     return [...prevData, updatedData];
            //   }
            // });

            // if (action === 'DELETE') {

            // }
            handleCancel();
          })
          .catch((error) => {
            swal("Error occurs while saving Customer Image Save.", "error");
          });
        }
      } catch (error) {
        swal("Error occurs while saving Product Image Save.", error);
      }
    }
  };


  //#endregion

  //#region GET 
  const setImgData = (index) => {

    const updatedData = Lstdet.find(item => item.Index === index);
    setDetailData(updatedData);
    console.log(detailData);
  }
  //#endregion
  const handleUploadClick = () => {
    fileInputRef.current.click();
  };
  return (
    <Fragment>
      {/* <MainPagetitle mainTitle="Finance" pageTitle={'Finance'}  parentTitle={'Home'} /> */}
      <div className="row ">
        <div className="card h-auto">
          <>
            <div className="col-lg-12 p-3 row">
              <div className="col-lg-4">
                <input
                  type="file"
                  id="fileupload"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={handleImageChange}
                />
                {
                  !detailData.ImageData &&
                  <button onClick={handleUploadClick} className="btn btn-outline-primary btn-xs form-upload mx-1">
                    Select Image
                  </button>
                }


                {detailData && detailData.ImageData && (
                  <div className="col-lg-12 p-3 row">
                    <div className="col-lg-4">
                      <input
                        type="button"
                        className="btn btn-outline-primary btn-xs form-upload mx-1"
                        onClick={() => SaveImage("ADD")}
                        value="Save Image"
                      />
                    </div>
                    <div className="col-lg-6">
                      <select className="border default-select dropdown-item p-2"
                        id="ContentType"
                        name="ContentType"
                        value={detailData.ContentType}
                        onChange={handleChange}
                      >
                        <option value="-1">Select Type</option>
                        <option value="Product">Product</option>
                        <option value="Content">Content</option>
                      </select>
                    </div>
                    <div className="col-lg-2">
                      <input
                        type="button"
                        className="btn btn-outline-primary btn-xs form-upload  mx-1"
                        onClick={() => handleCancel()}
                        value="Cancel"
                      />
                    </div>
                  </div>
                )}
                {detailData && detailData.ID > 0 && (
                  <button
                    className="btn btn-outline-primary btn-xs form-upload  mx-1"
                    onClick={() => SaveImage("DELETE")}
                  >
                    X
                  </button>
                )}

                <div className="row">
                  {detailData && detailData.ImageData && (
                    <img
                      src={`data:image/png;base64,${detailData.ImageData}`}
                      alt="Uploaded"
                      className="p-2"
                      style={{ width: "250px" }}
                    />
                  )}
                </div>
              </div>
              <div
                className="col-lg-8 table-active table-responsive"
                style={{ overflowX: "auto", maxHeight: "400px" }}
              >
                <div id="finance-tblwrapper" className="dataTables_wrapper">
                  <table
                    id="empoloyees-tbl1"
                    className="table ItemsCheckboxSec dataTable no-footer mb-0"
                  >
                    <thead>
                      <tr>
                        <th>File Name</th>
                        <th>Type</th>
                        <th>Image</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        <>
                          {Array.isArray(searchData) &&
                            searchData.map((item, index) => (
                              <tr key={item.Index}>
                                <td>
                                  <Link
                                    onClick={() => setImgData(item.Index)}
                                    className="text-primary"
                                  >
                                    {item.FileName}
                                  </Link>
                                </td>
                                <td> {item.ContentType}</td>
                                <td>
                                  <span>
                                    <img
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                      }}
                                      // src={item.ImagePath ? item.ImagePath : '/img/noimages.png'}
                                      src={
                                        "data:image/png;base64," +
                                        item.ImageData
                                      }
                                      alt=""
                                    />
                                  </span>
                                </td>
                              </tr>
                            ))}
                        </>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </Fragment>
  );
};

export default ProductImageUpload;
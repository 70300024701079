let Encategory = [
  { label: "Item Group", value: "ItemGroup" },
  // { label: "Qualification", value: "Qualification" },
  // { label: "Area", value: "Area" },
  // { label: "City", value: "City" },
  // { label: "State", value: "State" },
  { label: "Country", value: "Country" },
  { label: "User Role", value: "UserRole" },
  // { label: "Nationality", value: "Nationality" },
  // { label: "Duration", value: "Duration" },
  // { label: "Religion", value: "Religion" },
  ];
  let USER_ROLES={

     CategoryEntry:1
    ,UserPermission:2
    ,Category2Image:3
    ,SubCategoryEntry:4
    ,ProductMaster:5
    ,PDFDownload:6
    ,EventMaster:7
    ,ContactUs:8
    ,InquiryEntry:9
    ,ClientMaster:10
}

export {Encategory,USER_ROLES}
// import MainPagetitle from '../../layouts/MainPagetitle';
import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CMS_Category2ImageService from "../api/CMS_Category2ImageService";
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import Category2ImageSearch from './Category2ImageSearch';
import { toast } from "react-toastify";
import ComboSerive from "../api/ComboSerive";
import { generateRandomNumber } from '../../jsx/Common/Commonentry';
const Category2Image = () => {
  const [category, setcategory] = useState();
  const [refresh, setRefresh] = useState(false);
  let { pagekey } = useParams();
  const nav = useNavigate();
  const [image, setImage] = useState('');
  const [extension, setextension] = useState('');
  const [formData, setFormData] = useState({
    ID: 0
    , CategoryKey: '-1'
    , FileName: ''
    , ImageData: ''
    , Action: "ADD",
  });

  useEffect(() => {
    debugger
    document.title = 'Category 2 Image Master'; // Set your desired page title here
    handleCancel();
    setRefresh(false);
  }, [refresh]);

  const [buttonname, Setbuttonname] = useState('ADD');
  const [showDelButton, setshowDelButton] = useState(false);
  //#region combo/auto suggest
  useEffect(() => {
    ComboSerive.FillOtherCombo("CategoryName", "")
      .then((response) => {
        setcategory(response.data);
      })
      .catch((error) => {
        console.error("Error fetching combo api:", error);
      });
  }, []);
  //#endregion
  //#region Save Data
  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setImage(selectedImage);

    // Create a FileReader to read the selected image as a Base64 encoded string
    const reader = new FileReader();

    reader.onload = (event) => {
      // Set the Base64 encoded image string in state
      const base64Image = event.target.result;
      const base64Data = base64Image.split(',')[1];
      setImage(base64Data);

      const fileExtension = selectedImage.name.split('.').pop();
      setextension(fileExtension);
      setFormData({
        ...formData,
        // FileName: selectedImage.name,
        ImageData: base64Data
      });
      debugger
    };

    // Read the selected image as a Data URL (Base64 encoded)
    reader.readAsDataURL(selectedImage);
    console.log(selectedImage);
  };


  const uploadImage = (sAction) => {

    // if (image) {

      let errorMessage = "";

      try {
        
        if (formData.CategoryKey === "-1") {
          errorMessage += "Please select Category.<br>";
        }
        if (!image) {
          errorMessage += "Please select Image for category.<br>";
        }

        if (sAction === "DELETE") {
          formData.Action = 'DELETE';
        }
        else {
          if (pagekey > 0) {
            formData.Action = 'UPDATE';
          }
          else {
            formData.Action = 'ADD';
          }
        }
        if (errorMessage !== "") {

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: errorMessage,
          });
        }
        else {

          CMS_Category2ImageService.CMS_Category2ImageSave(formData)
            .then((response) => {

              swal("Category2Image Save Successfully", "", "success");
              handleCancel();
              setRefresh(true);
            })
            .catch((error) => {
              swal("Error occurs while saving Category2Image Save.", "error");
            });

        }


      } catch (error) {
        swal("Error occurs while saving Category2 Image Save.", error);
      }
    // }
  };
  const handleCancel = () => {
    Setbuttonname('ADD');
    setshowDelButton(false);
    setFormData({
      ID: 0
      , CategoryKey: '-1'
      , FileName: generateRandomNumber()
      , ImageData: ''
      , Action: "ADD",
    });
    setImage('');
    nav("/Category2Image");

  }
  function handleChange(evt) {
    setFormData({
      ...formData,
      [evt.target.name]: evt.target.value,
    });

  }
  //#endregion

  //#region Get Data
  useEffect(() => {
    
    if (formData && formData.ImageData) {
      setImage(formData.ImageData);
    } else {
      setImage('');
    }
  }, [formData])

  useEffect(() => {
    if (pagekey > 0) {
      
      setcategory2Image();
      setFormData({ ...formData, ID: pagekey });
      Setbuttonname('Update');
      setshowDelButton(true);

    }
  }, [pagekey]);
  function setcategory2Image() {

    if (pagekey === undefined) {
      return;
    }
    CMS_Category2ImageService.CMS_Category2ImageGET(pagekey)
      .then((res) => {
        if (res.data !== null && res.data.ID > 0) {
          
          setFormData(res.data);
        }
        else {
          toast.error("Invalid Job ID!", {
            position: toast.POSITION.TOP_RIGHT,
            role: "alert",
          });
        }

      })
      .catch((err) => {
        toast.error(err.message, {
          position: toast.POSITION.TOP_RIGHT,
          role: "alert",
        });
      });
  }
  //#endregion
  return (
    <Fragment>
      {/* <MainPagetitle mainTitle="Finance" pageTitle={'Finance'}  parentTitle={'Home'} /> */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-3 col-xxl-4">
            <div className="card h-auto">
              <div className="card-header">
                <h4 className="heading mb-0">Category 2 Image Entry </h4>
              </div>
              <div className="card-body">
                <form className="finance-hr" >
                  <div className="form-group mb-3">
                    <label > Category<span className="text-danger">*</span>
                    </label>
                    <select className="border default-select dropdown-item p-2"
                      id="CategoryKey"
                      name="CategoryKey"
                      value={formData.CategoryKey}
                      onChange={handleChange}
                    >
                      <option value="-1">Select One</option>
                      {Array.isArray(category) && category.map((item) => (
                        <option key={item.ComboID} value={item.ComboID}>{item.ComboValue}</option>
                      ))}
                    </select>
                  </div>


                  <div className="form-group mb-3">
                    <input type="file" accept="image/*" onChange={handleImageChange} />
                    <input type='button'
                      className='btn btn-outline-primary btn-sm form-upload mx-1'
                      onClick={() => uploadImage('ADD')}
                      value="Upload"
                    />

                    {formData.ID > 0 &&
                      <input type='button'
                        className='btn btn-outline-primary btn-sm form-upload'
                        onClick={() => uploadImage('DELETE')}
                        value="X"
                      />
                    }
                    {image &&
                      <img src={`data:image/png;base64,${image}`}
                        alt="Uploaded"
                        width="100" />}

                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-xl-9 col-xxl-8">
            <Category2ImageSearch isRefresh={refresh}></Category2ImageSearch>

          </div>
        </div>
      </div>
    </Fragment >
  );
};

export default Category2Image;
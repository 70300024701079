import http from "./Endpoint";

class CategoryService {
  
  FillCategory(data) {
    return http().get(`/App/FillCategory?CategoryType=${data}`);
  }
  // (string comboname, string condition = "")
  FillOtherCombo(comboname,condition) {
    return http().get(`/App/FillOtherCombo?comboname=${comboname}&condition=${condition}`);
  }
  uspAutoSugges(TableName,Prefix) {
    return http().get(`/App/uspAutoSugges?TableName=${TableName}&Prefix=${Prefix}`);
  }
}
export default new CategoryService();
import http from "./Endpoint";

class CMS_ClientMasterService {
  

  CMS_ClientMasterSave(formData) {
    
    return http().post("/CMS/CMS_ClientMasterSave", {
      ID: formData.ID,
      ClientName: formData.ClientName,
      CompanyName: formData.CompanyName,
      ImageByte: formData.ImageByte,
      ImageName: formData.ImageName,
      Action: formData.Action,

      
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
  }
  CMS_ClientMasterSearch(data) {
    return http().get(`/CMS/CMS_ClientMasterSearch?ClientName=${data.ClientName}`);
  }

  CMS_ClientMasterGET(ID) {
    return http().get(`/CMS/CMS_ClientMasterGET?ID=${ID}`);
  }

}
export default new CMS_ClientMasterService();
// import MainPagetitle from '../../layouts/MainPagetitle';
import React, { useState, useEffect, Fragment,Suspense } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CMS_PDFDownloadService from "../api/CMS_PDFDownloadService";
import swal from 'sweetalert';
import PDFDownloadSearch from './PDFDownloadSearch';
import { toast } from "react-toastify";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Swal from 'sweetalert2';
const PDFDownload = () => {
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // State to track loading
  let { pagekey } = useParams();
  const nav = useNavigate();
  const [file, setfile] = useState('');
  const [formData, setFormData] = useState({
    ID: 0
    , PDFData: ''
    , FileName: ''
    , Action: "ADD",
  });

  useEffect(() => {

    document.title = 'PDF Download'; // Set your desired page title here
    handleCancel();
    setRefresh(false);
  }, [refresh]);

  //#region Save Data
  const handlefileChange = (e) => {
    const selectedfile = e.target.files[0];
    
    if (selectedfile && selectedfile.type === 'application/pdf') {
      setfile(selectedfile);

      // Create a FileReader to read the selected file as a Base64 encoded string
      const reader = new FileReader();

      reader.onload = (event) => {
        // Set the Base64 encoded file string in state
        const base64file = event.target.result;
        const base64Data = base64file.split(',')[1];
        setfile(base64Data);
        setFormData({
          ...formData,
          FileName: selectedfile.name,
          PDFData: base64Data
        });
      };
      // Read the selected file as a Data URL (Base64 encoded)
      reader.readAsDataURL(selectedfile);

    } else {
      swal("Please upload a valid PDF file.", "error");
    }
  };


  const uploadfile = (sAction) => {

    // if (file) {
      let errorMessage = "";
      try {
        if (!file) {
          errorMessage += "Please select file for upload.<br>";
        }
        if (sAction === "DELETE") {
          formData.Action = 'DELETE';
        }
        else {
          if (pagekey > 0) {
            formData.Action = 'UPDATE';
          }
          else {
            formData.Action = 'ADD';
          }
        }
        if (errorMessage !== "") {

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: errorMessage,
          });
        }
        else {

        CMS_PDFDownloadService.CMS_PDFDownloadSave(formData)
          .then((response) => {

            swal("PDF Save Successfully", "", "success");
            handleCancel();
            setRefresh(true);
          })
          .catch((error) => {
            swal("Error occurs while saving PDF Save.", "error");
          });
        }

      } catch (error) {
        swal("Error occurs while saving PDF file Save.", error);
      }
    // }
  };
  const handleCancel = () => {

    setfile('null');
    setFormData({
      ID: 0
      , PDFData: ''
      , PDFData: ''
      , Action: "ADD",
    });
    nav("/PDFDownload");


  }

  //#endregion

  //#region Get Data
  useEffect(() => {

    if (formData && formData.PDFData) {
      setfile(formData.PDFData);
    } else {
      setfile('');
    }
  }, [formData])

  useEffect(() => {
    if (pagekey > 0) {
      setIsLoading(true); // Start loading
      setPDFDownload();
      setFormData({ ...formData, ID: pagekey });
    }
  }, [pagekey]);
  function setPDFDownload() {

    if (pagekey === undefined) {
      return;
    }

    CMS_PDFDownloadService.CMS_PDFDownloadGET(pagekey)
      .then((res) => {

        if (res.data !== null && res.data.ID > 0) {
          setFormData(res.data);
        } else {
          toast.error("Invalid Job ID!", {
            position: toast.POSITION.TOP_RIGHT,
            role: "alert",
          });
        }
      })
      .catch((err) => {

        toast.error(err.message, {
          position: toast.POSITION.TOP_RIGHT,
          role: "alert",
        });
      })
      .finally(() => {
        setIsLoading(false); // Stop loading, whether successful or not
      });
  }

  //#endregion
  return (
    <Fragment>
      <Suspense>
        {/* <MainPagetitle mainTitle="Finance" pageTitle={'Finance'}  parentTitle={'Home'} /> */}
        <div className="container-fluid">
          {isLoading ? (
            // Show loading spinner or progress bar when isLoading is true

            <div className="loading-overlay">
              <div className="progress-container">
                <ProgressBar style={{ height: "20px" }} animated now={96} label={`${96}%`} />
              </div>
            </div>


          ) : (
            // Your existing code when data is not loading

            <div className="row">
              <div className="col-xl-3 col-xxl-4">
                <div className="card h-auto">
                  <div className="card-header col-lg-12 row">
                    <div className="col-lg-6">
                      <h4 className="heading mb-0">PDF Download </h4>
                    </div>
                    <div className="col-lg-3">
                      <button type="button" className="btn btn-primary" onClick={() => handleCancel()}>Cancel</button>
                    </div>
                  </div>
                  <div className="card-body">
                    <form className="finance-hr" >

                      <div className="form-group mb-3">
                        <input type="file" accept=".pdf" onChange={handlefileChange} />
                        <input type='button'
                          className='btn btn-outline-primary btn-sm form-upload mx-1'
                          onClick={() => uploadfile('ADD')}
                          value="Upload"
                        />

                        {formData.ID > 0 &&
                          <input type='button'
                            className='btn btn-outline-primary btn-sm form-upload'
                            onClick={() => uploadfile('DELETE')}
                            value="X"
                          />
                        }
                          {file &&
                          <>
                                  <a
                                    href={formData.PDFPath}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                        style={{ width: "20px", height: "20px" }}
                                        src={'/img/file_pdf.png'}
                                        alt={formData.PDFPath}

                                    />
                                  </a>
                         </>}
                        </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-xl-9 col-xxl-8">
                <PDFDownloadSearch isRefresh={refresh}></PDFDownloadSearch>

              </div>
            </div>
          )}
        </div>
      </Suspense>
    </Fragment>
  );
};


export default PDFDownload;
import React,{useState, useEffect} from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import LogoutPage from './Logout';

import { IMAGES, SVGICON } from "../../constant/theme";
import Logoutbtn from "./Logoutbtn";
import { GetUserDetail } from '../../Common/Commonentry';

const userDetail = GetUserDetail();
const NotificationBlog =({classChange}) =>{

	
	return(
		<>
			<li>
				<div className="timeline-panel">
					<div className="media me-2">
						<img alt="images" width={50} src={IMAGES.Avatar} />
					</div>
					<div className="media-body">
						<h6 className="mb-1">Dr sultads Send you Photo</h6>
						<small className="d-block">29 July 2022 - 02:26 PM</small>
					</div>
				</div>
			</li>
			<li>
				<div className="timeline-panel">
					<div className={`media me-2 ${classChange}`}>KG</div>
					<div className="media-body">
						<h6 className="mb-1">Resport created successfully</h6>
						<small className="d-block">29 July 2022 - 02:26 PM</small>
					</div>
				</div>
			</li>
			<li>
				<div className="timeline-panel">
					<div className={`media me-2 ${classChange}`}><i className="fa fa-home" /></div>
					<div className="media-body">
						<h6 className="mb-1">Reminder : Treatment Time!</h6>
						<small className="d-block">29 July 2022 - 02:26 PM</small>
					</div>
				</div>
			</li>
		</>
	)
}
console.log(userDetail);
const Header = ({ onNote }) => {
	const [headerFix, setheaderFix] = useState(false);
	useEffect(() => {
		window.addEventListener("scroll", () => {
			setheaderFix(window.scrollY > 50);
		});
	}, []); 
	
  
  return ( 
    <div className={`header ${headerFix ? "is-fixed" : ""}`}>
      <div className="header-content">
        <nav className="navbar navbar-expand">
          	<div className="collapse navbar-collapse justify-content-between">
				<div className="header-left">
					{/* <div className="input-group search-area">
						<span className="input-group-text rounded-0">
							<Link to={"#"}>
								<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
									<circle cx="8.78605" cy="8.78605" r="8.23951" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
									<path d="M14.5168 14.9447L17.7471 18.1667" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
								</svg>
							</Link>
						</span>
						<input type="text" className="form-control rounded-0" placeholder="Search" />
					</div> */}
				</div>
				<ul className="navbar-nav header-right">			
											
					<li className="nav-item align-items-center header-border">
						<Logoutbtn />
					</li>
					<li className="nav-item ps-3">
						<Dropdown className="header-profile2">
							<Dropdown.Toggle className="nav-link i-false" as="div">
								<div className="header-info2 d-flex align-items-center">
									<div className="header-media">
										<img src={IMAGES.Tab1} alt="" />
									</div>
									<div className="header-info">
												<h6>{userDetail && userDetail.UserFullName}</h6>
												<p>{userDetail && userDetail.RoleName}</p>	
									</div>
									
								</div>
							</Dropdown.Toggle>
							<Dropdown.Menu align="end">
								<div className="card border-0 mb-0">
									<div className="card-header py-2">
										<div className="products">
											<img src={IMAGES.Tab1} className="avatar avatar-md" alt="" />
											<div>
												<h6>{userDetail && userDetail.UserFullName}</h6>
												<span>{userDetail && userDetail.RoleName}</span>	
											</div>	
										</div>
									</div>
									{/* <div className="card-body px-0 py-2">
										<Link to={"/app-profile"} className="dropdown-item ai-icon ">
											{SVGICON.User}{" "}
											<span className="ms-2">Profile </span>
										</Link>
										<Link to={"/app-profile"} className="dropdown-item ai-icon ">
											{SVGICON.Project}{" "}												
											<span className="ms-2">My Project</span><span className="badge badge-sm badge-secondary light rounded-circle text-white ms-2">4</span>
										</Link>
										<Link to={"#"} className="dropdown-item ai-icon ">
											{SVGICON.Message}{" "}
											<span className="ms-2">Message </span>
										</Link>
										<Link to={"/email-inbox"} className="dropdown-item ai-icon ">
											{SVGICON.Notification} {" "}
											<span className="ms-2">Notification </span>
										</Link>
									</div> */}
									<div className="card-footer px-0 py-2">
										{/* <Link to={"#"} className="dropdown-item ai-icon ">
											{SVGICON.Headersetting} {" "}
											<span className="ms-2">Settings </span>
										</Link>										 */}
										{/* <LogoutPage /> */}
									</div>
								</div>
								
							</Dropdown.Menu>
						</Dropdown>
					</li>						
				</ul>
			
			
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;

// import MainPagetitle from '../../layouts/MainPagetitle';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CMS_PDFDownloadService from "../api/CMS_PDFDownloadService";

const PDFDownloadSearch = ({ isRefresh }) => {
    const [searchData, setsearchData] = useState([]);
    // const PdfThumbnail = ({ pdfUrl }) => {
    //     const [thumbnail, setThumbnail] = useState(null);

    //     useEffect(() => {
    //       const loadingTask = pdfjsLib.getDocument(pdfUrl);
    //       loadingTask.promise.then((pdf) => {
    //         pdf.getPage(1).then((page) => {
    //           const viewport = page.getViewport({ scale: 0.2 });
    //           const canvas = document.createElement('canvas');
    //           const ctx = canvas.getContext('2d');
    //           canvas.height = viewport.height;
    //           canvas.width = viewport.width;

    //           page.render({
    //             canvasContext: ctx,
    //             viewport: viewport,
    //           }).promise.then(() => {
    //             setThumbnail(canvas.toDataURL());
    //           });
    //         });
    //       });
    //     }, [pdfUrl]);

    //     return thumbnail ? <img src={thumbnail} alt="PDF Thumbnail" /> : <div>Loading...</div>;
    //   };
    //#region Search
    const [searchFilter, setsearchFilter] = useState({
        // CategoryKey: '-1'
    });


    useEffect(() => {
        CMS_PDFDownloadService.CMS_PDFDownloadSearch()
            .then((response) => {

                setsearchData(response.data);

            })
            .catch((error) => {
                console.error("Error fetching category search:", error);
            });


    }, [isRefresh]);
    const PDFDownloadSearch = (e) => {
        e.stopPropagation();
        e.preventDefault();

        CMS_PDFDownloadService.CMS_PDFDownloadSearch()
            .then((response) => {
                // console.success("category search successfully", success);
                setsearchData(response.data);

            })
            .catch((error) => {
                console.error("Error fetching PDF search:", error);
            });

    };
    //#endregion
    function handleChange(evt) {
        setsearchFilter({
            ...searchFilter,
            [evt.target.name]: evt.target.name,
        });
    }

    return (
        <>
            <div className="card">
                <div className='col-lg-12 p-3 row hidden'>
                    <div className="form-group col-lg-3">
                        <label > Category Type<span className="text-danger">*</span>
                        </label>
                        <select className="border default-select dropdown-item p-2"
                            id="CategoryKey"
                            name="CategoryKey"
                            value={searchFilter.CategoryKey}
                            onChange={handleChange}
                        >
                            <option value="-1">Select All</option>
                            {/* {Array.isArray(category) && category.map((item) => (
                                <option key={item.ComboID} value={item.ComboID}>{item.ComboValue}</option>
                            ))} */}

                        </select>
                    </div>

                    <div className="form-group col-lg-1">
                        <label></label>
                        <button type="submit" className="btn btn-primary mb-3" onClick={PDFDownloadSearch}>Search</button>
                    </div>
                </div>

                <div className="card-body p-0">
                    <div
                        className="table-active table-responsive"
                        style={{ overflowX: "auto", maxHeight: "400px" }}
                    >
                        <div className="table-responsive active-projects manage-client">
                            <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">


                            </div>
                            <div id="finance-tblwrapper" className="dataTables_wrapper no-footer">
                                <table id="empoloyees-tbl1" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                    <thead>
                                        <tr>
                                            <th>File</th>
                                            <th>Image</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {searchData.map((item, index) => (
                                            <tr key={item.ID}>
                                                <td>
                                                    <Link to={"/PDFDownload/" + item.ID} className="text-primary">{item.FileName}</Link>
                                                </td>
                                                <td>
                                                    <a
                                                        href={item.PDFPath}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <img
                                                            style={{ width: "20px", height: "20px" }}
                                                            src={'/img/file_pdf.png'}
                                                            alt={item.PDFPath}

                                                        />
                                                    </a>
                                                </td>
                                                <td></td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PDFDownloadSearch;
// import MainPagetitle from '../../layouts/MainPagetitle';
import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CMS_SubCategoryService from "../api/CMS_SubCategoryService";
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import SubCategorySearch from './SubCategorySearch';
import { toast } from "react-toastify";
import { getProfile } from '../../services/AuthService';
import ComboSerive from "../api/ComboSerive";

const SubCategoryEntry = () => {
    const [refresh, setRefresh] = useState(false);
    let { pagekey } = useParams();
    const nav = useNavigate();
    const userDetail = JSON.parse(getProfile());
    const [formData, setFormData] = useState({
        SubCategoryKey: 0
        , CategoryKey: '-1'
        , SubCategoryName: ''
        , Status: false
        , Action: ''
        , UserID: userDetail.UserKey
    });

    useEffect(() => {

        document.title = 'Sub Category Master'; // Set your desired page title here
        handleCancel();
        setRefresh(false);
    }, [refresh]);

    const [buttonname, Setbuttonname] = useState('ADD');
    const [showDelButton, setshowDelButton] = useState(false);
    const [category, setcategory] = useState();
    //#region combo/auto suggest
    useEffect(() => {
        ComboSerive.FillOtherCombo("CategoryName", "")
            .then((response) => {
                setcategory(response.data);
            })
            .catch((error) => {
                console.error("Error fetching combo api:", error);
            });
    }, []);
    //#endregion
    //#region Save Data
    const SubCategorySave = (sAction) => {

        let errorMessage = "";
        if (formData.CategoryKey === "-1") {
            errorMessage += "Please select category.<br>";
        }
        if (!formData.SubCategoryName) {
            errorMessage += "Please enter sub category name.<br>";
        }

        if (sAction === "DELETE") {
            formData.Action = 'DELETE';
        }
        else {
            if (pagekey > 0) {
                formData.Action = 'UPDATE';
            }
            else {
                formData.Action = 'ADD';
            }
        }
         formData.UserID=userDetail.UserKey != null ? userDetail.UserKey : 1;
        if (errorMessage !== "") {

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                html: errorMessage,
            });
        }
        else {

            CMS_SubCategoryService.CMS_SubCategorySave(formData)
                .then((response) => {

                    swal("Sub Category Save Successfully", "", "success");
                    handleCancel();
                    setRefresh(true);
                })
                .catch((error) => {
                    swal("Error occurs while saving Sub Category Save.", "error");
                });

        }
    };
    const handleCancel = () => {
        Setbuttonname('ADD');
        setshowDelButton(false);
        setFormData({
            SubCategoryKey: 0
            , CategoryKey: '-1'
            , SubCategoryName: ''
            , Status: false
            , Action: 'ADD'
            , UserID: userDetail && userDetail.UserKey
        });
        nav("/SubCategoryEntry");

    }
    function handleChange(evt) {

        const name = evt.target.name;
        let value = "";
        if (name === "Status") {

            value = evt.target.checked;
        }
        else {
            value = evt.target.value;
        }
        setFormData({
            ...formData,
            [evt.target.name]: value,
        });
    }
    //#endregion

    //#region Get Data
    useEffect(() => {
        if (pagekey > 0) {

            setSubcat();
            setFormData({ ...formData, SubCategoryKey: pagekey });
            Setbuttonname('Update');
            setshowDelButton(true);

        }
    }, [pagekey]);
    function setSubcat() {

        if (pagekey === undefined) {
            return;
        }
        CMS_SubCategoryService.CMS_SubCategoryGet(pagekey)
            .then((res) => {
                if (res.data !== null && res.data.SubCategoryKey > 0) {
                    setFormData(res.data);
                }
                else {
                    toast.error("Invalid Job ID!", {
                        position: toast.POSITION.TOP_RIGHT,
                        role: "alert",
                    });
                }

            })
            .catch((err) => {
                toast.error(err.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    role: "alert",
                });
            });
    }
    //#endregion
    return (
        <Fragment>
            {/* <MainPagetitle mainTitle="Finance" pageTitle={'Finance'}  parentTitle={'Home'} /> */}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-3 col-xxl-4">
                        <div className="card h-auto">
                            <div className="card-header">
                                <h4 className="heading mb-0">Sub Category Entry </h4>
                            </div>

                            <div className="card-body">
                                <form className="finance-hr" >

                                    <div className="form-group mb-3">
                                        <label > Category<span className="text-danger">*</span>
                                        </label>
                                        <select className="border default-select dropdown-item p-2"
                                            id="CategoryKey"
                                            name="CategoryKey"
                                            value={formData.CategoryKey}
                                            onChange={handleChange}
                                        >
                                            <option value="-1">Select One</option>
                                            {Array.isArray(category) && category.map((item) => (
                                                <option key={item.ComboID} value={item.ComboID}>{item.ComboValue}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label > Sub Category Name<span className="text-danger">*</span>
                                        </label>
                                        <input type="text"
                                            className="form-control"
                                            id="SubCategoryName"
                                            name="SubCategoryName"
                                            value={formData.SubCategoryName}
                                            onChange={handleChange}
                                            placeholder="Sub Category Name" />
                                    </div>
                                    <div className="form-group col-lg-6 mb-3">
                                        <label > Status<span className="text-danger mx-3"></span>
                                        </label>
                                        <input
                                                type="checkbox"
                                                defaultChecked
                                                className="form-check-input"
                                                id="Status"
                                                name="Status"
                                                value={formData.Status}
                                                checked={formData.Status}
                                                onChange={handleChange}
                                                required
                                            />
                                    </div>
                                   
                                    <button type="button" className="btn btn-primary mb-3 mx-1" onClick={() => SubCategorySave('ADD')}>{buttonname}</button>
                                    {showDelButton &&
                                        <button type="button" className="btn btn-primary mb-3" onClick={() => SubCategorySave('DELETE')}>Delete</button>}
                                    <button type="button" className="btn btn-primary mb-3 mx-2" onClick={() => handleCancel()}>Cancel</button>

                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-9 col-xxl-8">
                        <SubCategorySearch isRefresh={refresh}></SubCategorySearch>

                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default SubCategoryEntry;
// import MainPagetitle from '../../layouts/MainPagetitle';
import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CMS_ClientMasterService from "../api/CMS_ClientMasterService";
import ComboSerive from "../api/ComboSerive";

const ClientMasterSearch = ({ isRefresh }) => {
    const nav = useNavigate();
    const [searchData, setsearchData] = useState([]);
    //#region Search
    const [searchFilter, setsearchFilter] = useState({
        ClientName: ''
    });

    useEffect(() => {
        CMS_ClientMasterService.CMS_ClientMasterSearch(searchFilter)
            .then((response) => {

                setsearchData(response.data);

            })
            .catch((error) => {
                console.error("Error fetching Client search:", error);
            });


    }, [isRefresh]);
    const ClientMasterSearch = (e) => {
        e.stopPropagation();
        e.preventDefault();

        CMS_ClientMasterService.CMS_ClientMasterSearch(searchFilter)
            .then((response) => {
                // console.success("client search successfully", success);
                setsearchData(response.data);

            })
            .catch((error) => {
                console.error("Error fetching client search:", error);
            });

    };
    //#endregion
    function handleChange(evt) {

        const name = evt.target.name;
        let value = evt.target.value;
        setsearchFilter({
            ...searchFilter,
            [evt.target.name]: value,
        });
    }
    return (
        <>
            <div className="card">
                <div className='col-lg-12 p-3 row'>
                    <div className="form-group col-lg-3">
                        <label > Category<span className="text-danger">*</span>
                        </label>
                        <input ClientName="text"
                            className="form-control form-txtarea"
                            id="ClientName"
                            name="ClientName"
                            value={searchFilter.ClientName}
                            onChange={handleChange}
                            placeholder="Client Name" />
                    </div>

                    <div className="form-group col-lg-1">
                        <label></label>
                        <button type="submit" className="btn btn-primary mb-3" onClick={ClientMasterSearch}>Search</button>
                    </div>
                </div>

                <div className="card-body p-0">
                    <div
                        className="table-active table-responsive"
                        style={{ overflowX: "auto", maxHeight: "400px" }}
                    >
                        <div className="table-responsive active-projects manage-client">
                            <div id="finance-tblwrapper" className="dataTables_wrapper no-footer">
                                <table id="empoloyees-tbl1" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                    <thead>
                                        <tr>
                                            
                                            <th>Company</th>
                                            <th>Place</th>
                                            <th>Image</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {searchData.map((item, index) => (
                                            <tr key={item.CategoryKey}>
                                                <td>
                                                    <Link to={"/ClientMaster/" + item.ID} className="text-primary">{item.CompanyName}</Link>
                                                </td>
                                                <td>
                                                    {item.ClientName}
                                                </td>
                                                <td>
                                                    <div className="card card-body border-dark">
                                                        <img
                                                            style={{ width: "100px", height: "100px" }}
                                                            src={item.ImagePath ? item.ImagePath : '/img/noimages.png'}
                                                            alt=""
                                                        // onError={handleImageError}
                                                        />
                                                    </div>
                                                </td>


                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ClientMasterSearch;
import axios from "axios";

export default () => {
  return axios.create({
    baseURL: "https://easycoolapi.pos-erp.in/",

    //baseURL: "http://localhost:5187/",
    // baseURL: "http://localhost:5144/",
    headers: {
        "Content-type": "application/json"
      },
    headers: {
      "Access-Control-Allow-Origin": "*",
    }
  });
}

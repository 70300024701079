import React from "react";
import {  useSelector } from "react-redux";
import {  Routes, Route, Outlet } from "react-router-dom";

/// Css
import "./index.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";


/// Dashboard
import Home from "./components/Dashboard/Home";
 
/// Pages
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";

// import { ThemeContext } from "../context/ThemeContext";

/// Customer
import CategoryEntry from "./Setting/CategoryEntry";
import CategorySearch from "./Setting/CategorySearch";
import UserPermission from "./Setting/UserPermission";
import UserPermissionSearch from "./Setting/UserPermissionSearch";


//CMS
import Category2Image from "./CMS/Category2Image";
import Category2ImageSearch from "./CMS/Category2ImageSearch";

import SubCategoryEntry from "./CMS/SubCategoryEntry";
import SubCategorySearch from "./CMS/SubCategorySearch";

import ProductMaster from "./CMS/ProductMaster";
import ProductMasterSearch from "./CMS/ProductMasterSearch";

import PDFDownload from "./CMS/PDFDownload";
import PDFDownloadSearch from "./CMS/PDFDownloadSearch";

import EventMaster from "./CMS/EventMaster";
import EventMasterSearch from "./CMS/EventMasterSearch";

import ContactUs from "./CMS/EventMaster";
import InquiryEntry from "./CMS/InquiryEntry";

import RequireAuth from './pages/RequireAuth';
import {USER_ROLES} from './Common/Enum';

import ClientMaster from "./CMS/ClientMaster";
import ClientMasterSearch from "./CMS/ClientMasterSearch";

const allroutes = [
  // Dashboard
 
    { url: "", component: <Home />,allowedRoles:"*"   },
    { url: "dashboard", component: <Home />,allowedRoles:"*"   },
   

//Setting
    
     { url: "CategoryEntry/:pagekey?", component: <CategoryEntry /> , allowedRoles: USER_ROLES.CategoryEntry },
     { url: "CategorySearch", component: <CategorySearch />, allowedRoles: USER_ROLES.CategoryEntry },

     { url: "UserPermission/:roleid?", component: <UserPermission />, allowedRoles: USER_ROLES.UserPermission },
     { url: "UserPermissionSearch", component: <UserPermissionSearch />, allowedRoles: USER_ROLES.UserPermission },
     
   //CMS
    
     { url: "Category2Image/:pagekey?", component: <Category2Image />, allowedRoles: USER_ROLES.Category2Image },
     { url: "Category2ImageSearch", component: <Category2ImageSearch />, allowedRoles: USER_ROLES.Category2Image },

     { url: "SubCategoryEntry/:pagekey?", component: <SubCategoryEntry />, allowedRoles: USER_ROLES.SubCategoryEntry },
     { url: "SubCategorySearch", component: <SubCategorySearch />, allowedRoles: USER_ROLES.SubCategoryEntry },

     { url: "ProductMaster/:pagekey?", component: <ProductMaster />, allowedRoles: USER_ROLES.ProductMaster },
     { url: "ProductMasterSearch", component: <ProductMasterSearch />, allowedRoles: USER_ROLES.ProductMaster },

     { url: "PDFDownload/:pagekey?", component: <PDFDownload />, allowedRoles: USER_ROLES.PDFDownload },
     { url: "PDFDownloadSearch", component: <PDFDownloadSearch />, allowedRoles: USER_ROLES.PDFDownload },

     { url: "EventMaster/:pagekey?", component: <EventMaster />, allowedRoles: USER_ROLES.EventMaster },
     { url: "EventMasterSearch", component: <EventMasterSearch />, allowedRoles: USER_ROLES.EventMaster },

    //  { url: "ContactUs/:pagekey?", component: <ContactUs />, allowedRoles: USER_ROLES.ContactUs },
    //  { url: "InquiryEntry/:pagekey?", component: <InquiryEntry />, allowedRoles: USER_ROLES.InquiryEntry },

     { url: "ClientMaster/:pagekey?", component: <ClientMaster />, allowedRoles: USER_ROLES.ClientMaster },
     { url: "ClientMasterSearch", component: <ClientMasterSearch />, allowedRoles: USER_ROLES.ClientMaster },

    
     
];

const Markup = () => {   
  
  function NotFound(){    
    const url = allroutes.map((route) => route.url);
    let path = window.location.pathname
    path = path.split('/')
    path = path[path.length - 1]    
      
    if(url.indexOf(path) <= 0){     
      return <Error404 />
    }
  }
  
    return (
      <>
          <Routes>              
              <Route path='/page-lock-screen' element= {<LockScreen />} />
              <Route path='/page-error-400' element={<Error400/>} />            
              <Route path='/page-error-403' element={<Error403/>} />
              <Route path='/page-error-404' element={<Error404/>} />
              <Route path='/page-error-500' element={<Error500/>} />
              <Route path='/page-error-503' element={<Error503/>} />     
              <Route path='/Product_rect' component={() => {
            window.location.href = '/Product_rect';
            return null;
          }} />
              <Route  element={<MainLayout />} > 
              {allroutes.map((data, i) => (

                <Route element={<RequireAuth allowedRoles={data.allowedRoles} />}>

                  <Route
                    key={i}
                    exact
                    path={`${data.url}`}
                    element={data.component}
                  />
                </Route>
                
              ))}
              </Route>                
        
              <Route path='*' element={<NotFound/>} />     
          </Routes>     
          <ScrollToTop />          
      </>
    );       
};


  function MainLayout(){      
    const sideMenu = useSelector(state => state.sideMenu);
    return (
      <div id="main-wrapper" className={`show ${ sideMenu ? "menu-toggle" : ""}`}>  
          <Nav />
          <div className="content-body" style={{ minHeight: window.screen.height - 45 }}>          
            <Outlet />   
          </div>
        <Footer />
      </div>
    )
  };



export default Markup;
